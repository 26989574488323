import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SizeGuideChart } from '@nm-namshi-frontend/services/models/SizeGuideChart';
import { SizeGuideMap } from '@nm-namshi-frontend/services/models/SizeGuideMap';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { ProductFullResponse, ProductSimpleResponse, ProductVariant } from '@nm-namshi-frontend/services';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import ModalSelect from '@nm-namshi-frontend/core/components/forms/ModalSelect';
import Table, { TRows } from '@nm-namshi-frontend/core/components/Table';
import HorizontalProductBox from '@nm-namshi-frontend/core/page_components/shared/product/HorizontalProductBox';
import Select from '@nm-namshi-frontend/core/components/forms/Select';
import Tabs from '@nm-namshi-frontend/core/components/TabsV2';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import type { TOption } from '@nm-namshi-frontend/core/types';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';

import styles from './SizingGuideModal.module.scss';

type TProps = {
    open: boolean;
    onClose: () => void;
    productData: ProductFullResponse;
    onSelect: (sku: string) => void;
    selectedSku: string;
    renderCTA: (props: {
        onSuccess?: () => void;
        analyticsData: { eventLocation: TCartEventLocation; sizeSelected?: string };
    }) => React.ReactNode;
    onSuccess?: () => void;
};

const IS_MOBILE = isSchmatalog();
enum TabsEnum {
    Conversions = 'conversions',
    Measurements = 'measurements',
}

const SizingGuideModal: React.FC<TProps> = ({
    onClose,
    onSelect,
    onSuccess,
    open,
    productData,
    renderCTA,
    selectedSku,
}) => {
    const { t } = useTranslation('catalog');
    const { sizeGuide, variants } = productData;

    const selectedVariant = variants?.find((variant) => variant.sku === selectedSku);

    const tabs: (TOption & { show: boolean })[] = [
        {
            label: t('conversions-tab'),
            value: TabsEnum.Conversions,
            show: !!sizeGuide?.sizeMap?.header?.length,
        },
        { label: t('measurements-tab'), value: TabsEnum.Measurements, show: !!sizeGuide?.sizeChart },
    ];

    const [selectedTab, setSelectedTab] = useState<TabsEnum>(tabs[0]?.value);

    const isSizeAvailable = useCallback(
        (variantSku: string) => {
            const variant = variants?.find((item) => item.sku === variantSku);
            return !!(variant?.maxQty && variant?.maxQty > 0);
        },
        [!!variants],
    );

    const renderSelectedTabContent = () => {
        if (selectedTab === TabsEnum.Measurements) {
            return (
                sizeGuide &&
                sizeGuide.sizeChart &&
                sizeGuide.sizeStandard && (
                    <MeasurementTab
                        sizeChart={sizeGuide.sizeChart}
                        onSelect={onSelect}
                        isSizeAvailable={isSizeAvailable}
                        sizeStandard={sizeGuide.sizeStandard}
                        selectedSku={selectedSku}
                        parentSku={productData.sku}
                        variants={productData.variants as ProductVariant[]}
                    />
                )
            );
        }

        return (
            sizeGuide &&
            sizeGuide.sizeMap &&
            sizeGuide.sizeStandard && (
                <ConversionTab
                    isSizeAvailable={isSizeAvailable}
                    sizeMap={sizeGuide.sizeMap}
                    onSelect={onSelect}
                    sizeStandard={sizeGuide.sizeStandard}
                    selectedSku={selectedSku}
                    parentSku={productData.parentSku}
                    variants={productData.variants as ProductVariant[]}
                />
            )
        );
    };

    return (
        <Modal
            showAsSidePanel
            open={open}
            onClose={onClose}
            title={t('size-guide-label')}
            desktopWidth="75%"
            desktopMaxWidth="650px"
            mobileHeight="100%"
            contentClassName={styles.modalContent}
            footer={renderCTA({
                onSuccess,
                analyticsData: {
                    eventLocation: selectedTab === TabsEnum.Measurements ? 'measurements' : 'chart',
                    sizeSelected: selectedVariant?.title,
                },
            })}
        >
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <HorizontalProductBox
                        product={productData as ProductSimpleResponse}
                        selectedVariant={selectedVariant}
                    />
                </div>

                <div className={styles.tabsContainer}>
                    <Tabs<TabsEnum> selectedTab={selectedTab} tabs={tabs} onTabSelect={setSelectedTab} />
                </div>

                {renderSelectedTabContent()}
            </div>
        </Modal>
    );
};

type TTabProps = {
    sizeStandard: string;
    onSelect: (variantSku: string) => void;
    isSizeAvailable: (variantSku: string) => boolean;
    selectedSku: string;
    parentSku: string;
    variants: ProductVariant[];
};
const MeasurementTab: React.FC<TTabProps & { sizeChart: SizeGuideChart }> = ({
    isSizeAvailable,
    onSelect,
    parentSku,
    selectedSku,
    sizeChart,
    sizeStandard,
    variants,
}) => {
    const sizeMeasurementOption: TOption[] = useMemo(
        () =>
            sizeChart.units.map((item) => ({
                label: item.displayName,
                value: item.displayName,
            })) ?? [],
        [],
    );

    const [selectedSize, setSelectedSize] = useState<string>(sizeMeasurementOption[0]?.value);
    const headers: string[] = sizeChart.units[0]?.header?.filter((headerItem) => headerItem !== sizeStandard) ?? [];

    const getHeaders = () => {
        const tableHeader: (string | JSX.Element)[] = sizeMeasurementOption
            ? [
                  IS_MOBILE ? (
                      <ModalSelect
                          options={sizeMeasurementOption}
                          defaultValue={sizeMeasurementOption[0]?.value}
                          onChange={setSelectedSize}
                      />
                  ) : (
                      <Select
                          options={sizeMeasurementOption}
                          onChange={setSelectedSize}
                          value={selectedSize}
                          id="measurements-select"
                          isSearchable={false}
                          size="small"
                      />
                  ),
              ]
            : [];

        tableHeader.push(...headers);
        return tableHeader;
    };

    const getRows = () => {
        const tableRows: TRows[] = [];

        sizeChart?.units
            .find(({ displayName }) => displayName === selectedSize)
            ?.rows.forEach((row) =>
                tableRows.push({
                    id: row.sku,
                    cells: [...row.row],
                    customSelectedStyles: clsx(
                        row.sku === selectedSku && styles.selectedRow,
                        !isSizeAvailable(row.sku) && styles.disabled,
                    ),
                    icon:
                        variants?.find((item) => item.sku === row.sku)?.isRocket &&
                        (row.sku === selectedSku ? (
                            <Icon name="rocketPlain" size={8} color="theme" />
                        ) : (
                            <Icon name="rocket" size={15} />
                        )),
                }),
            );
        return tableRows;
    };

    useEffect(() => {
        trackEvent({
            event: 'impressionSizeGuide',
            defaultMatrix: sizeStandard,
            tab: 'Measurements',
            conversionAvailable: '0',
            measurementAvailable: '1',
            sku: parentSku,
            selectedMatrix: selectedSize,
        });
    }, [selectedSize]);

    return <Table headers={getHeaders()} rows={getRows()} onRowClick={onSelect} />;
};

const ConversionTab: React.FC<TTabProps & { sizeMap: SizeGuideMap }> = ({
    isSizeAvailable,
    onSelect,
    parentSku,
    selectedSku,
    sizeMap,
    sizeStandard,
    variants,
}) => {
    const tabHeaders: string[] = sizeMap?.header ?? [];
    const options =
        sizeMap?.header
            .filter((item) => item !== sizeStandard)
            .map((column) => ({
                label: column,
                value: column,
            })) ?? [];

    const [selectedSizeRunIndex, setSelectedSizeRunIndex] = useState<number>(0);

    const getHeaders = (): (string | JSX.Element)[] => {
        let headers: (string | JSX.Element)[] = [];

        if (tabHeaders.length === 2) {
            headers = [...tabHeaders];
        } else {
            const modalSelect = IS_MOBILE ? (
                <ModalSelect
                    options={options}
                    defaultValue={options[selectedSizeRunIndex]?.value}
                    onChange={(newValue: string) =>
                        setSelectedSizeRunIndex(options.findIndex((item) => item.label === newValue))
                    }
                />
            ) : (
                <Select
                    options={options}
                    value={options[selectedSizeRunIndex]?.value}
                    onChange={(newValue: string) => {
                        setSelectedSizeRunIndex(options.findIndex((item) => item?.value === newValue));
                    }}
                    id="conversion-chart-select"
                    isSearchable={false}
                    size="small"
                />
            );

            headers.push(sizeStandard);
            headers.push(modalSelect);
        }

        return headers;
    };

    const getRows = () => {
        const rows: TRows[] = [];

        sizeMap?.rows.forEach((row) => {
            rows.push({
                id: row.sku,
                cells: [row.row[0], row.row[selectedSizeRunIndex + 1]],
                customSelectedStyles: clsx(
                    row.sku === selectedSku && styles.selectedRow,
                    !isSizeAvailable(row.sku) && styles.disabled,
                ),
                icon:
                    variants?.find((item) => item.sku === row.sku)?.isRocket &&
                    (row.sku === selectedSku ? (
                        <Icon name="rocketPlain" size={8} color="theme" />
                    ) : (
                        <Icon name="rocket" size={15} />
                    )),
            });
        });
        return rows;
    };

    return (
        <Table
            headers={getHeaders()}
            rows={getRows()}
            onRowClick={(val) => {
                trackEvent({
                    event: 'impressionSizeGuide',
                    defaultMatrix: sizeStandard,
                    tab: 'Conversions',
                    conversionAvailable: '1',
                    measurementAvailable: '0',
                    sku: parentSku,
                    selectedMatrix: options[selectedSizeRunIndex]?.value,
                });

                onSelect(val);
            }}
        />
    );
};

export default SizingGuideModal;
