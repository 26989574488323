import React from 'react';

import { ProductSimpleResponse } from '@nm-namshi-frontend/services';

import styles from './ProductBoxFooter.module.scss';
import ProductDiscountTag from './ProductDiscountTag';

type TProps = {
    product: ProductSimpleResponse;
};

const ProductBoxFooter = ({ product }: TProps) => {
    const { fouponText } = product;

    if (fouponText) {
        return (
            <div className={styles.container}>
                <div className={styles.otherInfoContainer}>
                    <div className={styles.fouponContainer}>{fouponText}</div>
                </div>
            </div>
        );
    }

    return null;
};

export default ProductBoxFooter;
