import React, { useEffect, useMemo, useRef } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import {
    CatalogDetailsResponse,
    ProductFullResponse,
    ProductVariant,
    ProductWishlistResponse,
} from '@nm-namshi-frontend/services';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { useOutsideClick } from '@nm-namshi-frontend/core/hooks/useOutsideClick';
import Button from '@nm-namshi-frontend/core/components/Button';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';

import styles from './SizeSelector.module.scss';
import SizeGuide from './SizeGuide';

const IS_MOBILE = isSchmatalog();

type TSizeSelectorProps = {
    onSelect: (sku: string) => void;
    selectedSku: string;
    productData: ProductWishlistResponse;
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    renderCTA?: (props: {
        size?: 'small' | 'medium';
        onSuccess?: () => void;
        analyticsData: { eventLocation: TCartEventLocation; sizeSelected?: string };
    }) => React.ReactNode;
};

const SizeSelector: React.FC<TSizeSelectorProps> = (props) => {
    const { isOpen, onSelect, productData, renderCTA, selectedSku, setIsOpen } = props;

    const { t } = useTranslation('wishlist');
    const ref = useRef<HTMLDivElement>(null);

    useOutsideClick({
        callback: () => {
            if (!IS_MOBILE) {
                setIsOpen(false);
            }
        },
        ref,
    });

    const selectedVariant = useMemo(
        () => productData.variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!productData.variants, selectedSku],
    );

    useEffect(() => {
        if (isOpen) trackEvent({ event: 'impressionSizeSelector' });
    }, [isOpen]);

    if (!productData.variants?.length) return null;

    if (IS_MOBILE) {
        return (
            <Modal
                open={isOpen}
                onClose={() => {
                    onSelect('');
                    setIsOpen(false);
                }}
                isContentCovering
                showCloseButton={false}
                mobileMaxHeight="90%"
                footer={renderCTA?.({
                    onSuccess: () => setIsOpen(false),
                    analyticsData: {
                        eventLocation: 'sizeSelector',
                        sizeSelected: selectedVariant?.title,
                    },
                })}
            >
                <SizeList
                    productData={productData}
                    onSelect={onSelect}
                    selectedSku={selectedSku}
                    renderCTA={renderCTA}
                />
            </Modal>
        );
    }

    return (
        <div ref={ref} className={styles.container}>
            <Button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                disabled={productData.stockInfo?.code === 'out_of_stock'}
                variant="secondary"
            >
                <div className={styles.btnStyles}>
                    <p>{selectedVariant?.title ? ` ${selectedVariant.title}` : t('common.select-size')}</p>
                    <div className={styles.chevronLabel}>
                        <Icon name="chevronDownBold" size={14} />
                    </div>
                </div>
            </Button>

            <div className={clsx(styles.dropdown, isOpen && styles.open)}>
                {isOpen && (
                    <SizeList
                        productData={productData}
                        selectedSku={selectedSku}
                        onSelect={(sku) => {
                            onSelect(sku);
                            setIsOpen(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

const SizeList: React.FC<{
    productData: ProductWishlistResponse | ProductFullResponse;
    onSelect: (sku: string) => void;
    selectedSku: string;
    renderCTA?: (props: {
        onSuccess?: () => void;
        analyticsData: { eventLocation: TCartEventLocation; sizeSelected?: string };
    }) => React.ReactNode;
}> = (props) => {
    const { onSelect, productData, renderCTA, selectedSku } = props;

    const { t } = useTranslation('wishlist');

    const { uri } = productData;

    const { data: catalogResponse, isFetched } = useQuery(
        [REACT_QUERY_KEYS.GET_PRODUCT_DETAILS, uri],
        () =>
            getApiInstance().product.catalog({
                requestBody: {
                    uri,
                },
            }),
        {
            keepPreviousData: true,
            enabled: IS_MOBILE,
        },
    );

    return (
        <div>
            {IS_MOBILE && renderCTA && (
                <div className={styles.sizeListHeader}>
                    <div className={styles.title}>{t('common.select-size')}</div>
                    {isFetched ? (
                        <SizeGuide
                            selectedSku={selectedSku}
                            onSelect={onSelect}
                            productData={
                                ((catalogResponse?.data as CatalogDetailsResponse)?.product as ProductFullResponse) ||
                                (productData as ProductFullResponse)
                            }
                            renderCTA={renderCTA}
                        />
                    ) : (
                        <Skeleton width="100px" />
                    )}
                </div>
            )}

            <ul className={styles.sizeList}>
                {productData.variants?.map((item: ProductVariant) => {
                    const isSelected = item.sku === selectedSku;

                    return (
                        <li key={item.sku} className={styles.sizeItem}>
                            <button
                                className={clsx(
                                    styles.sizeBtn,
                                    selectedSku === item.sku && styles.selected,
                                    item?.stockInfo?.code === 'out_of_stock' && styles.disabled,
                                )}
                                type="button"
                                onClick={() => {
                                    if (item.stockInfo?.code !== 'out_of_stock') {
                                        onSelect(item.sku);
                                    }
                                }}
                            >
                                <p dir="ltr" className={styles.sizeLabel}>
                                    {item.title}
                                </p>
                                {item.stockInfo?.label && (
                                    <p
                                        style={{
                                            color: item.stockInfo.color || 'black',
                                            fontWeight: 300,
                                        }}
                                    >
                                        {item.stockInfo.code === 'low_stock' && item.stockInfo?.label}
                                        {item.stockInfo.code === 'out_of_stock' && item.stockInfo?.label}
                                    </p>
                                )}
                                {item.isRocket && (
                                    <Icon
                                        name={isSelected ? 'rocketPlain' : 'rocket'}
                                        size={isSelected ? 8 : 15}
                                        color={isSelected ? 'theme' : undefined}
                                    />
                                )}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SizeSelector;
