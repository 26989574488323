import React from 'react';

import clsx from 'clsx';

import styles from './ProductIndicators.module.scss';

type TProps = {
    currentSlide: number;
    slideCount: number;
};

const ProductIndicators = ({ currentSlide, slideCount }: TProps) => {
    if (slideCount <= 1) {
        return null;
    }

    return (
        <ul className={styles.slideDots}>
            {[...Array(slideCount)].map((_, index) => (
                <li key={`${index + 1}-dot`}>
                    <button
                        type="button"
                        className={clsx(styles.indicator, currentSlide === index && styles.active)}
                        aria-label={`slide ${index} bullet`}
                    />
                </li>
            ))}
        </ul>
    );
};

export default ProductIndicators;
