import { toast } from 'react-toastify';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';

type TProps = {
    analyticsData: {
        title: string;
        parentSku: string;
        eventLocation: TCartEventLocation;
        sizeSelected?: string;
        isRocket: boolean;
        isGlobal?: boolean;
        pageType?: string;
        pagePath?: string;
    };
    price: number;
    sku: string;
    isFouponApplied?: boolean;
    onSuccess?: () => void;
    pageType?: string;
};
const useAddToBag = ({ analyticsData, isFouponApplied, onSuccess, pageType, price, sku }: TProps) => {
    const queryClient = useQueryClient();
    const { conversionRates } = useConfigContext();
    const { isProductSponsored } = useCatalogStore();

    const { locale } = useAppPathname();
    const { currencyCodeEN } = getLocaleConfig(locale);

    const { isLoading: isAddToBagLoading, mutate: addToBag } = useMutation(
        [REACT_QUERY_KEYS.ADD_ITEM_TO_SESSION],
        () =>
            getApiInstance().session.addItem({
                requestBody: {
                    sku,
                    ...(isFouponApplied
                        ? {
                              useFoupon: isFouponApplied,
                          }
                        : {}),
                },
                ...(pageType ? { xPage: pageType } : {}),
            }),
        {
            onSuccess: (response) => {
                if (response.toastMessages?.length) {
                    response.toastMessages.forEach((message) => toast.warning(message));
                }
                queryClient.setQueryData([REACT_QUERY_KEYS.GET_ACTIVE_SESSION], response);

                // Check if sku has been updated
                const { updatedSkus = [] } = response;
                const isSkuUpdated = updatedSkus.some(({ sku: currentSku }) => currentSku === sku);

                if (isSkuUpdated) {
                    onSuccess?.();
                }

                trackAddToBag();
            },
            onError: (error: any) => {
                toast.error(error?.body?.error || error.toString());
            },
        },
    );

    const trackAddToBag = () => {
        const convertedPrice = convertPrice({
            conversionRates,
            price,
            toCurrency: currencyCodeEN,
        });

        trackEvent({
            event: 'cart_add',
            sku,
            parentSku: analyticsData.parentSku,
            price: convertedPrice,
            value: convertedPrice, // Since always single qty is added to cart, value = price
            quantity: 1, // Always adding single qty to cart
            currency: currencyCodeEN,
            title: analyticsData.title,
            event_location: analyticsData.eventLocation,
            sizeSelected: analyticsData.sizeSelected,
            isRocket: analyticsData.isRocket,
            isGlobal: analyticsData.isGlobal,
            isSponsored: isProductSponsored,
            ...(analyticsData.pageType ? { pageType: analyticsData.pageType } : {}),
            ...(analyticsData.pagePath ? { pagePath: analyticsData.pagePath } : {}),
        });
    };

    return {
        isAddToBagLoading,
        addToBag,
    };
};

export default useAddToBag;
