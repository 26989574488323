import { create, StoreApi } from 'zustand';

import { NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import { COOKIE_ID } from '../constants/uiConstants';
import { getCookie, setCookie } from '../utils/helper';

type TDepartmentStoreV2 = {
    navigationData: NavDepartmentsResponse | null;
    setNavigationData: (arg0: NavDepartmentsResponse | null) => void;
    selectedDepartmentId: string;
    setSelectedDepartmentId: (arg0: string) => void;
    selectedSubDepartmentId: string;
    setSelectedSubDepartmentId: (arg0: string) => void;
    selectedCategoryId: string;
    setSelectedCategoryId: (arg0: string) => void;
    selectedDepartmentOrSubDepartmentId: string;
    setSelectedDepartmentOrSubDepartmentId: (arg0: string) => void;
};

const store = (set: StoreApi<TDepartmentStoreV2>['setState']) => ({
    navigationData: null,
    setNavigationData: (navigationData: NavDepartmentsResponse | null) => {
        set({ navigationData });
    },
    selectedDepartmentId: getCookie(COOKIE_ID.SELECTED_DEPARTMENT_ID) as string,
    setSelectedDepartmentId: (selectedDepartmentId: string) => {
        setCookie(COOKIE_ID.SELECTED_DEPARTMENT_ID, selectedDepartmentId);
        set({ selectedDepartmentId });
    },
    selectedSubDepartmentId: getCookie(COOKIE_ID.SELECTED_SUBDEPARTMENT_ID) as string,
    setSelectedSubDepartmentId: (selectedSubDepartmentId: string) => {
        setCookie(COOKIE_ID.SELECTED_SUBDEPARTMENT_ID, selectedSubDepartmentId);
        set({ selectedSubDepartmentId });
    },
    // Used in category page to go to default category page tab
    selectedCategoryId: '',
    setSelectedCategoryId: (selectedCategoryId: string) => {
        set({ selectedCategoryId });
    },
    // Used to obtain subDepartmentId(if part of selected department) or fallback to departmentId
    selectedDepartmentOrSubDepartmentId: '',
    setSelectedDepartmentOrSubDepartmentId: (selectedDepartmentOrSubDepartmentId: string) => {
        set({ selectedDepartmentOrSubDepartmentId });
    },
});

const useDepartmentStoreV2 = create<TDepartmentStoreV2>(store);

export default useDepartmentStoreV2;
