import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

const useIsSettleXAddress = () => {
    const [isXAddressKeySattled, setIsXAddressKeySattled] = useState(false);
    const selectedAddressKey = useCustomerStore((store) => store.selectedAddressKey);
    const setSelectedAddressKey = useCustomerStore((store) => store.setSelectedAddressKey);
    const customerData = useCustomerStore((store) => store.customerData);

    const isUserLoggedIn = !!customerData;

    const isEnabled = isUserLoggedIn && !selectedAddressKey;

    useQuery([REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES], () => getApiInstance().customer.getCustomerAddresses({}), {
        onSuccess: (data) => {
            const newSelectedAddressKey = data?.selected?.addressKey || '';
            setSelectedAddressKey(newSelectedAddressKey);
        },
        onSettled() {
            setIsXAddressKeySattled(true);
        },
        enabled: isEnabled,
    });

    return { isXAddressKeySattled: isEnabled ? isXAddressKeySattled : true };
};

export default useIsSettleXAddress;
