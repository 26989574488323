import React, { useEffect, useMemo, useState } from 'react';

import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import { isSchmatalog, isTimerSaleActive } from '@nm-namshi-frontend/core/utils/helper';
import { TLanguage } from '@nm-namshi-frontend/core/types';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import CountDownTimer from '../CountdownTimer';
import styles from './TimerSale.module.scss';
import AlteredLink from '../AlteredLink';

export enum TimerSaleVariant {
    banner = 'banner', // PDP only
    tag = 'tag',
}

type TProps = {
    className?: string;
    timerSaleConfig?: Timer;
    onExpiry?: () => void;
    variant?: TimerSaleVariant;
    lang: TLanguage;
    hideIcon?: boolean;
};

const IS_MOBILE = isSchmatalog();

// this needs to be an svg because it can inherit currentColor
const TimerIcon = ({ size }: { size: number }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1191_365)">
            <path
                d="M18.443 21.8181H19.3635V19.8545H18.443V18.8727C18.4413 17.6799 18.1492 16.5081 17.5952 15.472C17.0413 14.4359 16.2446 13.5711 15.2831 12.9621C14.9564 12.7559 14.7612 12.4251 14.7612 12.0775V11.9224C14.7612 11.5748 14.9564 11.2439 15.2822 11.0387C16.2439 10.4297 17.0407 9.56468 17.5948 8.52842C18.1489 7.49216 18.4412 6.32017 18.443 5.12722V4.1454H19.3635V2.18176H4.63623V4.1454H5.55669V5.12722C5.55855 6.32017 5.85084 7.49216 6.40491 8.52842C6.95899 9.56468 7.75587 10.4297 8.71753 11.0387C9.04337 11.2439 9.2385 11.5738 9.2385 11.9224V12.0775C9.2385 12.4251 9.04337 12.7559 8.71753 12.9611C7.75587 13.5702 6.95899 14.4352 6.40491 15.4715C5.85084 16.5077 5.55855 17.6797 5.55669 18.8727V19.8545H4.63623V21.8181H18.443ZM16.6021 4.1454V5.12722C16.6007 6.18979 16.2765 7.22325 15.678 8.07267H8.32173C7.72324 7.22325 7.39899 6.18979 7.39759 5.12722V4.1454H16.6021ZM9.65271 14.6528C10.5465 14.0893 11.0794 13.1271 11.0794 12.0775V11.9999H12.9203V12.0775C12.9203 13.1261 13.4533 14.0893 14.3479 14.6528C15.175 15.1778 15.8262 15.9672 16.2091 16.909H7.79063C8.17366 15.9669 8.82519 15.1775 9.65271 14.6528Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1191_365">
                <rect width="18" height="18" fill="currentColor" transform="translate(3 3)" />
            </clipPath>
        </defs>
    </svg>
);

const TimerSale = ({
    className = '',
    hideIcon,
    lang,
    onExpiry,
    timerSaleConfig,
    variant = TimerSaleVariant.banner,
}: TProps) => {
    const [isTimerVisible, setIsTimerVisible] = useState(false);

    const { t } = useTranslation('common');
    const { locale } = useAppPathname();

    const timerSale: Timer | undefined = useMemo(() => {
        if (isTimerSaleActive(timerSaleConfig)) {
            return timerSaleConfig;
        }

        return undefined;
    }, [timerSaleConfig]);

    useEffect(() => {
        // Render this on the client only
        // Reason: server-side rendering is causing a mismatch in the number of seconds showing up on the server render and client render
        setIsTimerVisible(true);
    }, []);

    if (!(timerSale && timerSale?.endDate) || !isTimerVisible) {
        return null;
    }

    const currentTime = Date.now() / 1000;
    const startTime = Math.floor(new Date(timerSale.startDate).getTime() / 1000);
    const endTime = Math.floor(new Date(timerSale.endDate).getTime() / 1000);
    const title = lang === 'en' ? timerSale.titleEn : timerSale.titleAr;
    const subtitle = lang === 'en' ? timerSale.subtitleEn : timerSale.subtitleAr;

    const styleConfig = {
        ...(timerSale.textColor && { '--clr-timer-body': timerSale.textColor }),
    } as React.CSSProperties;

    const TagComponent = () => (
        <span className={styles.timerTag} style={styleConfig}>
            {!hideIcon && <TimerIcon size={10} />}
            <span className={styles.label}>{t('ends-in')}</span>
            <div dir="ltr">
                <CountDownTimer
                    className={styles.timer}
                    digitStyle={styles.timerDigit}
                    endTime={endTime}
                    hideSeparator
                    onExpiry={onExpiry}
                />
            </div>
        </span>
    );

    const BannerComponent = () => (
        <AlteredLink
            locale={locale}
            href={`/${timerSaleConfig?.filterKeyCode || ''}/`}
            className={clsx(styles.timerBanner, className)}
            style={styleConfig}
            onClick={() => {
                trackEvent({
                    event: 'timerClick',
                    contentClickUrl: `/${timerSaleConfig?.filterKeyCode}`,
                });
            }}
        >
            <div className={styles.titleContainer}>
                <div>
                    <TimerIcon size={IS_MOBILE ? 14 : 16} />
                    {title && <div className={styles.timerBannerTitle}>{parse(title)}</div>}
                </div>
                {subtitle && (
                    <div>
                        <div className={styles.timerBannerSubTitle}>{parse(subtitle)}</div>
                        <Icon name="chevronForward" size={15} />
                    </div>
                )}
            </div>
            <div dir="ltr">
                <CountDownTimer
                    className={styles.timer}
                    endTime={endTime}
                    hideSeparator
                    showExtendedUnits
                    showUnits
                    onExpiry={onExpiry}
                    digitStyle={styles.timerUnit}
                />
            </div>
        </AlteredLink>
    );

    if (startTime < currentTime && endTime > currentTime) {
        switch (variant) {
            case TimerSaleVariant.banner:
                return <BannerComponent />;
            case TimerSaleVariant.tag: {
                return <TagComponent />;
            }

            default:
                return <></>;
        }
    }
    return null;
};
export default TimerSale;
