import React from 'react';

import Button from '@nm-namshi-frontend/core/components/Button';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';
import useAddToBag from '@nm-namshi-frontend/core/hooks/useAddToBag';

type TProps = {
    size?: 'small' | 'medium';
    analyticsData: {
        title: string;
        parentSku: string;
        eventLocation: TCartEventLocation;
        sizeSelected?: string;
        isRocket: boolean;
        isGlobal?: boolean;
    };
    sku: string;
    price: number;
    isAddToBagDisabled: boolean;
    text: string;
    isFouponApplied?: boolean;
    areAddToBagPrerequisitesLoading: boolean;
    onSuccess?: () => void;
    onNoSizeSelected?: () => void;
    pageType?: string;
};
const ConversionCTA = ({
    analyticsData,
    areAddToBagPrerequisitesLoading,
    isAddToBagDisabled,
    isFouponApplied = false,
    onNoSizeSelected,
    onSuccess,
    pageType,
    price,
    size,
    sku,
    text,
}: TProps) => {
    const { addToBag, isAddToBagLoading } = useAddToBag({
        analyticsData,
        isFouponApplied,
        onSuccess,
        price,
        sku,
        pageType,
    });

    const handleAddToBag = () => {
        if (!sku) {
            onNoSizeSelected?.();
            return;
        }

        addToBag();
    };

    return (
        <div data-testid="addToBag">
            <Button
                size={size}
                onClick={handleAddToBag}
                loading={areAddToBagPrerequisitesLoading || isAddToBagLoading}
                disabled={isAddToBagDisabled}
                variant="tertiary"
            >
                {text}
            </Button>
        </div>
    );
};

export default ConversionCTA;
