import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ProductFullResponse } from '@nm-namshi-frontend/services';
import { trackPage } from '@nm-namshi-frontend//core/utils/analytics';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';

import styles from './SizeGuide.module.scss';
import SizingGuideModal from './SizingGuideModal';

type TProps = {
    productData: ProductFullResponse;
    selectedSku: string;
    onSelect: (sku: string) => void;
    renderCTA: (props: {
        onSuccess?: () => void;
        analyticsData: { eventLocation: TCartEventLocation; sizeSelected?: string };
    }) => React.ReactNode;
};

const SizeGuide: React.FC<TProps> = (props) => {
    const { onSelect, productData, renderCTA, selectedSku } = props;

    const { t } = useTranslation('catalog');
    const [isOpen, setIsOpen] = useState(false);

    const { sizeGuide } = productData;

    const showSizeGuideLabel = sizeGuide && sizeGuide.sizeStandard && (sizeGuide?.sizeMap || sizeGuide?.sizeChart);

    useEffect(() => {
        if (isOpen) {
            trackPage({
                event: 'page',
                pageType: 'size_chart',
            });
        }
    }, [isOpen]);

    if (!showSizeGuideLabel) return null;

    return (
        <>
            <SizingGuideModal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                productData={productData}
                selectedSku={selectedSku}
                onSelect={onSelect}
                onSuccess={() => setIsOpen(false)}
                renderCTA={renderCTA}
            />

            <button className={styles.btn} type="button" onClick={() => setIsOpen(true)}>
                {t('show-size-chart')}
            </button>
        </>
    );
};

export default SizeGuide;
