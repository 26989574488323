import React from 'react';

import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import { DiscountTag } from '@nm-namshi-frontend/services';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './ProductDiscountTag.module.scss';
import AlteredLink from '../AlteredLink';

type TProps = {
    tag: DiscountTag;
    isClickable?: boolean;
    size?: 'small' | 'normal';
};

const IS_MOBILE = isSchmatalog();

const ProductDiscountTag = ({ isClickable = false, size = 'normal', tag }: TProps) => {
    const { t } = useTranslation();
    const { isArabic, locale } = useAppContext();

    const styleConfig = {
        backgroundColor: tag.bgColor ? tag.bgColor : 'var(--color-timer)',
        ...(tag.textColor && { color: tag.textColor }),
    };

    const sizeClass = size === 'small' ? styles.small : '';

    if (isClickable && tag.filterKeyCode) {
        const subtitleContent = isArabic ? tag.subtitleAr || tag.subtitleEn : tag.subtitleEn;
        const ADDITIONAL_SUBTITLE_HEIGHT = 15;
        const TAG_HEIGHT = (IS_MOBILE ? 30 : 39) + (subtitleContent ? ADDITIONAL_SUBTITLE_HEIGHT : 0);
        const TagTailComponent = subtitleContent ? TagTall : Tag;
        return (
            <AlteredLink
                locale={locale}
                href={`/${tag.filterKeyCode}/`}
                className={styles.discountTagFull}
                onClick={() => {
                    trackEvent({
                        event: 'discountClick',
                        contentClickUrl: `/${tag.filterKeyCode}`,
                    });
                }}
            >
                <span className={styles.content} style={{ ...styleConfig, height: TAG_HEIGHT }}>
                    <span className={styles.textContainer}>
                        <span className={styles.text}>{parse(isArabic ? tag.tagAr || tag.tagEn : tag.tagEn)}</span>
                        {subtitleContent && <span className={styles.subtitleText}>{parse(subtitleContent)}</span>}
                    </span>
                    <span className={styles.action}>{t('seeAll')}</span>
                </span>
                <div className={styles.tail}>
                    <TagTailComponent color={styleConfig.backgroundColor} height={TAG_HEIGHT} />
                </div>
            </AlteredLink>
        );
    }

    return (
        <div className={clsx(styles.discountTag, sizeClass)}>
            <div
                className={styles.text}
                style={{
                    color: tag.textColor,
                    backgroundColor: styleConfig.backgroundColor,
                }}
            >
                {parse(isArabic ? tag.tagAr || tag.tagEn : tag.tagEn)}
            </div>
            <div className={styles.tagWrapper}>
                <Tag color={styleConfig.backgroundColor} height={size === 'small' ? 18 : 20} />
            </div>
        </div>
    );
};

const Tag = ({ color, height = 20 }: { color: string; height?: number }) => {
    const TAG_RATIO = 8 / height;
    return (
        <svg
            width={TAG_RATIO * height}
            height={height}
            viewBox="0 0 8 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0L0 0L0 24H8V18.63C7.78111 18.6542 7.55868 18.6667 7.33333 18.6667C4.01962 18.6667 1.33333 15.9804 1.33333 12.6667C1.33333 9.35296 4.01962 6.66667 7.33333 6.66667C7.55868 6.66667 7.78111 6.67909 8 6.70329V0Z"
                fill={color}
            />
        </svg>
    );
};

const TagTall = ({ color, height = 45 }: { color: string; height?: number }) => {
    const TAG_RATIO = 9 / 45;
    return (
        <svg
            width={TAG_RATIO * height}
            height={height}
            viewBox="0 0 9 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9 0H0V45H9V29.9836C8.83281 29.9945 8.66414 30 8.49416 30C4.30455 30 0.908203 26.6421 0.908203 22.5C0.908203 18.3579 4.30455 15 8.49416 15C8.66414 15 8.83281 15.0055 9 15.0164V0Z"
                fill={color}
            />
        </svg>
    );
};

export default ProductDiscountTag;
