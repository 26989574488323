import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import Button from '@nm-namshi-frontend/core/components/Button';
import { ProductWishlistResponse, ProductWishlistResponse1 } from '@nm-namshi-frontend/services/index';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useParamState from '@nm-namshi-frontend/core/hooks/useParamState';

import styles from './DeleteWishlistItemModal.module.scss';

type TDeleteWishlistModal = {
    analyticsData: {
        parentSku: string;
        isRocket: boolean;
    };
    isOpen: boolean;
    onClose: () => void;
    product: ProductWishlistResponse | ProductWishlistResponse1; // SERVICE_LAYER: Temporary usage of ProductWishlistResponse1 until BE fixes openapi.json

    onSuccess?: () => void;
};

const DeleteWishlistItemModal = ({
    analyticsData,
    isOpen,
    onClose,
    onSuccess,
    product,
}: TDeleteWishlistModal): JSX.Element => {
    const { t } = useTranslation('wishlist');
    const queryClient = useQueryClient();
    const [currentPage = 1] = useParamState('page');

    const { isLoading, mutate: wishlistDeleteItem } = useMutation(
        () =>
            getApiInstance().wardrobe.deleteItem({
                parentSku: product.parentSku,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS, currentPage]);
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_SINGLE_WARDROBE_DATA]);
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
                toast.success(t('toast.delete-item-from-wishlist'));

                trackEvent({
                    event: 'wishlist_remove',
                    sku: product.parentSku,
                    parentSku: analyticsData.parentSku,
                    isRocket: analyticsData.isRocket,
                });

                onSuccess?.();
            },
            onError: (error: string) => {
                toast.error(error.toString());
            },
        },
    );

    return (
        <Modal
            desktopWidth="400px"
            onClose={onClose}
            open={isOpen}
            title={t('delete.delete-wishlist-item')}
            footer={
                <div data-testid="deleteWishlistItem">
                    <Button variant="tertiary" onClick={() => wishlistDeleteItem()} loading={isLoading}>
                        {t('common.yes')}
                    </Button>
                </div>
            }
        >
            <div className={styles.description}>{t('delete.delete-message')}</div>
        </Modal>
    );
};

export default DeleteWishlistItemModal;
