import { V1_SEARCH_DEPARTMENTS_CONFIG } from '@nm-namshi-frontend/core/config';

/**
 * @param  {string} subDepartmentId : Incoming departmentId & subDepartmentId (which is from the new navigation-categories structure)
 * @returns Corresponding V1 subDepartment code from V1_SEARCH_DEPARTMENTS_CONFIG, for an incoming departmentId & subDepartmentId
 * Note: Remove this function once we combine the navigation-categories behavior across mweb
 */
export const getMappedSubDepartmentIdFromConfig = (
    departmentId: string,
    subDepartmentId: string,
    restrictToSubDepartments?: boolean,
): string | null => {
    const subDepartmentDataFromConfig = V1_SEARCH_DEPARTMENTS_CONFIG.find(
        ({ departmentIdV2: departmentIdFromConfig, subDepartmentIdV2: subDepartmentIdFromConfig }) => {
            // Get config object only based on subDepartmentId match
            if (restrictToSubDepartments) {
                return subDepartmentId === subDepartmentIdFromConfig;
            }

            const isDepartmentIdMatching = departmentIdFromConfig === departmentId;

            // If there exists a subDepartmentIdFromConfig for a departmentIdFromConfig - then ensure that the incoming subDepartmentId matches subDepartmentIdFromConfig
            if (isDepartmentIdMatching && !!subDepartmentIdFromConfig) {
                const isSubDepartmentIdMatching = subDepartmentIdFromConfig === subDepartmentId;

                // Get config object on both subDepartmentId or departmentId
                return isDepartmentIdMatching && isSubDepartmentIdMatching;
            }

            // Get config object either on subDepartmentId or departmentId match
            return isDepartmentIdMatching;
        },
    );

    return subDepartmentDataFromConfig?.searchSubDepartmentV1 ?? null;
};
