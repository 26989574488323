import React, { useEffect, useMemo, useRef, useState } from 'react';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './NativeImageSlider.module.scss';

const PRE_LOADED_CAROUSEL_IMAGES = 1;

type TWithKey = {
    key: string;
};

type TProps<T extends TWithKey> = {
    renderBadgeText?: () => JSX.Element | null;
    oneImage?: boolean;
    lazyLoad?: boolean;
    onSwipe?: (item: T) => void;
    onClick?: (idx: number) => void;
    autoplay?: boolean;
    autoplayInterval?: number;
    animationDuration?: number; // in ms
    goAround?: boolean;
    renderBottomControls?: (props: {
        currentSlide: number;
        slideCount: number;
        goToSlide: (idx: number) => void;
    }) => JSX.Element | null;
    data: T[];
    renderSlide: (item: T) => JSX.Element;
    renderEmpty?: () => JSX.Element;
    rightAndLeftControls?: boolean;
};

const smoothScroll = (el: HTMLDivElement, targetPosition: number, duration: number): Promise<void> =>
    new Promise((resolve) => {
        const element = el;
        const startPosition = element.scrollLeft;
        const distance = targetPosition - startPosition;
        let startTime: number | null = null;
        element.classList.add(styles.noSnap);

        const animation = (currentTime: number) => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = easeOutCubic(timeElapsed, startPosition, distance, duration);
            element.scrollLeft = run;
            if (timeElapsed < duration) requestAnimationFrame(animation);
            else {
                element.scrollLeft = targetPosition;
                element.classList.remove(styles.noSnap);
                resolve();
            }
        };

        const easeOutCubic = (time: number, b: number, c: number, d: number) => {
            const t = time / d - 1; // Normalize time and shift it to start from -1
            return c * (t * t * t + 1) + b; // Cubic ease-out formula
        };
        requestAnimationFrame(animation);
    });

const nextSlide = (
    slider: HTMLDivElement | null,
    isArabic: boolean,
    goAround: boolean,
    animationDuration: number,
): Promise<void> =>
    new Promise((resolve) => {
        let nextPosition;
        if (!slider) return;
        const { offsetWidth, scrollLeft, scrollWidth } = slider;

        const nextExpectedPosition = Math.abs(scrollLeft) + offsetWidth;

        if (isArabic) {
            nextPosition = Math.abs(scrollLeft) + offsetWidth >= scrollWidth ? 0 : scrollLeft - offsetWidth;
        } else {
            // if we reached the end of the slider, go to the start, else go to the next image
            nextPosition = nextExpectedPosition >= scrollWidth ? 0 : nextExpectedPosition;
        }

        if (nextPosition !== 0 || goAround) {
            // scroll to next image with animation
            smoothScroll(slider, nextPosition, animationDuration).then(() => {
                setTimeout(() => {
                    resolve();
                }, 100);
            });
        } else {
            resolve();
        }
    });

const previousSlide = (
    slider: HTMLDivElement | null,
    isArabic: boolean,
    goAround: boolean,
    animationDuration: number,
): Promise<void> =>
    new Promise((resolve) => {
        let nextPosition;
        if (!slider) return;
        const { offsetWidth, scrollLeft, scrollWidth } = slider;

        const lastPosition = scrollWidth - offsetWidth;

        if (isArabic) {
            nextPosition = Math.abs(scrollLeft) === 0 ? -1 * lastPosition : scrollLeft + offsetWidth;
        } else {
            nextPosition = scrollLeft === 0 ? lastPosition : scrollLeft - offsetWidth;
        }

        if (nextPosition !== 0 || goAround) {
            // scroll to previous image with animation
            smoothScroll(slider, nextPosition, animationDuration).then(() => {
                setTimeout(() => {
                    resolve();
                }, 100);
            });
        } else {
            resolve();
        }
    });

// !IMPORTANT: Do not use lazyLoad with goAround enabled
const NativeImageSlider = <T extends TWithKey>({
    animationDuration = 1000,
    autoplay = false,
    autoplayInterval = 3000,
    data,
    goAround = false,
    lazyLoad = false,
    onClick,
    onSwipe,
    oneImage,
    renderBadgeText,
    renderBottomControls,
    renderEmpty,
    renderSlide,
    rightAndLeftControls,
}: TProps<T>) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const isUserScrolling = useRef(false);
    const isAutoScroll = useRef(false);
    const isButtonScroll = useRef(false);
    const { isArabic } = useAppContext();
    const timeout = useRef<NodeJS.Timeout>();

    const firstImageNode =
        data.length > 0 ? (
            <div key={data[0].key} onClick={() => onClick?.(0)} className={styles.imgContainer}>
                {renderSlide(data[0])}
            </div>
        ) : (
            renderEmpty?.() || <div />
        );
    const [imageNodes, setImageNodes] = useState<JSX.Element[]>([firstImageNode]);

    const images = useMemo(
        () =>
            data.map((item: T, idx: number) => {
                if (!lazyLoad || idx <= currentIdx + PRE_LOADED_CAROUSEL_IMAGES) {
                    // loading current and next image only
                    // OR
                    // loading all images in case lazy laod is explicitly disabled
                    return (
                        <div key={item.key} onClick={() => onClick?.(idx)} className={styles.imgContainer}>
                            {renderSlide(item)}
                        </div>
                    );
                }

                return <div key={item.key} />;
            }),
        [data, lazyLoad, currentIdx],
    );

    // for scrolling using right and left buttons want when multiple clicks are done in a short time to queue the actions
    const moveSlide = (direction: 'next' | 'previous') => {
        if (isButtonScroll.current) {
            return;
        }
        isButtonScroll.current = true;

        if (direction === 'next') {
            nextSlide(sliderRef.current, isArabic, goAround, animationDuration).then(() => {
                isButtonScroll.current = false;
            });
        } else {
            previousSlide(sliderRef.current, isArabic, goAround, animationDuration).then(() => {
                isButtonScroll.current = false;
            });
        }
    };

    const handleSwipe = (e: any) => {
        // This is to stop autoScrolling when user is scrolling
        // if event is not triggered by auto scroll
        if (!isAutoScroll.current) {
            isUserScrolling.current = true;

            clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                isUserScrolling.current = false;
            }, autoplayInterval);
        }

        const { clientWidth, scrollLeft } = e.target;
        let newIdx;
        const absoluteIdx = Math.round(Math.abs(scrollLeft) / clientWidth);

        // if goAround is enabled then there are two extra images at the start and end
        if (goAround) {
            if (absoluteIdx === 0) {
                newIdx = data.length - 1;
            } else if (absoluteIdx === data.length + 1) {
                newIdx = 0;
            } else {
                newIdx = absoluteIdx - 1;
            }
            if (
                Math.abs(scrollLeft) >= (data.length + 1) * clientWidth ||
                (scrollLeft <= 0 && !isArabic) ||
                (scrollLeft >= 0 && isArabic)
            ) {
                e.target.classList.add(styles.noSnap);

                let scrollPosition =
                    Math.abs(scrollLeft) >= (data.length + 1) * clientWidth ? clientWidth : data.length * clientWidth;
                if (isArabic) scrollPosition *= -1;
                e.target.scrollLeft = scrollPosition;

                setTimeout(() => {
                    e.target.scrollLeft = scrollPosition;
                    e.target.classList.remove(styles.noSnap);
                }, 10);

                return;
            }
        } else {
            newIdx = absoluteIdx;
        }

        if (newIdx !== currentIdx) {
            onSwipe?.(data[newIdx]);
            setCurrentIdx(newIdx);
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;
        if (goAround) {
            setTimeout(() => {
                if (slider && goAround) {
                    if (isArabic) slider.scrollLeft = -slider.offsetWidth;
                    else slider.scrollLeft = slider.offsetWidth;
                }
            }, 50);
        }
        if (autoplay) {
            let interval: NodeJS.Timeout;

            if (slider) {
                interval = setInterval(() => {
                    // Scroll to the next image if user is not scrolling
                    if (!isUserScrolling.current) {
                        isAutoScroll.current = true;
                        nextSlide(slider, isArabic, goAround, animationDuration).then(() => {
                            isAutoScroll.current = false;
                        });
                    }
                }, autoplayInterval);
            }

            return () => {
                clearInterval(interval);
                clearTimeout(timeout.current);
            }; // Cleanup interval on unmount
        }
        return undefined;
    }, []);

    // if autoplay is enabled set imageNodes to include the first and last image duplicates
    useEffect(() => {
        const newImageNodes = [...images];
        if (goAround) {
            // add first image to the end and last image to the start for infinite scroll
            newImageNodes.push(
                <div key="first-duplicate" onClick={() => onClick?.(0)} className={styles.imgContainer}>
                    {renderSlide(data[0])}
                </div>,
            );

            newImageNodes.unshift(
                <div key="last-duplicate" onClick={() => onClick?.(data.length - 1)} className={styles.imgContainer}>
                    {renderSlide(data[data.length - 1])}
                </div>,
            );
        }
        setImageNodes(newImageNodes);
    }, [images]);

    if (!data.length) {
        return (
            <div className={styles.container}>
                <div className={styles.imgContainer}>{renderEmpty?.()}</div>
            </div>
        );
    }

    if (oneImage || data.length === 1) {
        return (
            <div className={styles.container}>
                {renderBadgeText?.()}
                <div onClick={() => onClick?.(0)} className={styles.imgContainer}>
                    {renderSlide(data[0])}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {renderBadgeText?.()}
            <div className={styles.slider} onScroll={handleSwipe} ref={sliderRef}>
                {imageNodes}
            </div>
            {renderBottomControls?.({
                currentSlide: currentIdx,
                slideCount: data.length,
                goToSlide: (idx: number) => {
                    const slider = sliderRef.current;
                    if (slider) {
                        let nextPosition = goAround ? (idx + 1) * slider.offsetWidth : idx * slider.offsetWidth;
                        if (isArabic) nextPosition *= -1;
                        smoothScroll(slider, nextPosition, animationDuration);
                    }
                },
            })}
            {rightAndLeftControls && (
                <>
                    <div className={styles.leftControlsContainer}>
                        <div className={styles.centerLeft}>
                            <button
                                type="button"
                                onClick={() => moveSlide('previous')}
                                className={styles.buttonContainer}
                            >
                                <Icon name="chevronBack" />
                            </button>
                        </div>
                    </div>
                    <div className={styles.rightControlsContainer}>
                        <div className={styles.centerRight}>
                            <button type="button" onClick={() => moveSlide('next')} className={styles.buttonContainer}>
                                <Icon name="chevronForward" />
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default NativeImageSlider;
