import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { ProductFullResponse, ProductVariant } from '@nm-namshi-frontend/services';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import Button from '@nm-namshi-frontend/core/components/Button';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './QuickSizeSelector.module.scss';

type TSizeSelectorProps = {
    productData?: ProductFullResponse;
    isOpen: boolean;
    onClose: () => void;
    ctaText: string;
    onSelect: (variant: ProductVariant) => void;
    isLoading: boolean;
};

const QuickSizeSelector: React.FC<TSizeSelectorProps> = ({
    ctaText,
    isLoading,
    isOpen,
    onClose,
    onSelect,
    productData,
}) => {
    const { t } = useTranslation();

    const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();

    useEffect(() => {
        if (isOpen) trackEvent({ event: 'impressionSizeSelector', pagePath: '/cart/similarProducts/' });
    }, [isOpen]);

    if (!productData || !productData?.variants?.length) return null;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            showCloseButton={false}
            contentClassName={styles.modalContent}
            desktopHeight="500px"
            mobileMaxHeight="90%"
            desktopWidth="400px"
            footer={
                <Button
                    disabled={!selectedVariant}
                    loading={isLoading}
                    variant="tertiary"
                    onClick={() => {
                        if (selectedVariant) {
                            onSelect(selectedVariant);
                        }
                    }}
                >
                    {ctaText}
                </Button>
            }
        >
            <div>
                <div className={styles.sizeListHeader}>
                    <div className={styles.title}>{t('out-of-stuck.select-size')}</div>
                </div>

                <ul className={styles.sizeList}>
                    {productData.variants?.map((item: ProductVariant) => {
                        const isSelected = item.sku === selectedVariant?.sku;

                        return (
                            <li key={item.sku} className={styles.sizeItem}>
                                <button
                                    className={clsx(
                                        styles.sizeBtn,
                                        selectedVariant?.sku === item.sku && styles.selected,
                                        item?.stockInfo?.code === 'out_of_stock' && styles.disabled,
                                    )}
                                    type="button"
                                    onClick={() => {
                                        if (item.stockInfo?.code !== 'out_of_stock') {
                                            setSelectedVariant(item);
                                        }
                                    }}
                                >
                                    <p className={styles.sizeLabel}>{item.title}</p>
                                    {item.stockInfo?.label && (
                                        <p
                                            style={{
                                                color: item.stockInfo.color || 'black',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {item.stockInfo.code === 'low_stock' && item.stockInfo?.label}
                                            {item.stockInfo.code === 'out_of_stock' && item.stockInfo?.label}
                                        </p>
                                    )}
                                    {item.isRocket && (
                                        <Icon
                                            name={isSelected ? 'rocketPlain' : 'rocket'}
                                            size={isSelected ? 8 : 15}
                                            color={isSelected ? 'theme' : undefined}
                                        />
                                    )}
                                    {item.isGlobal && (
                                        <Icon
                                            name={isSelected ? 'plane' : 'global'}
                                            size={isSelected ? 17 : 18}
                                            color={isSelected ? 'purple-l1' : undefined}
                                        />
                                    )}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Modal>
    );
};

export default QuickSizeSelector;
