import clsx from 'clsx';

import {
    ProductFullResponse,
    ProductHeadline,
    ProductSimpleResponse,
    ProductVariant,
} from '@nm-namshi-frontend/services';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';

import styles from './HorizontalProductBox.module.scss';

type THorizontalProductBox = {
    product: ProductSimpleResponse | ProductFullResponse | ProductHeadline | null;
    selectedVariant?: ProductVariant;
    isLoading?: boolean;
};

const HorizontalProductBox: React.FC<THorizontalProductBox> = ({ isLoading, product, selectedVariant }) => {
    const imageUrl = (product as ProductHeadline)?.imageKey || (product as ProductFullResponse)?.imageKeys?.[0];
    const normalPrice = selectedVariant?.normalPrice || product?.normalPrice || 0;
    const salePrice = selectedVariant?.salePrice || product?.salePrice || 0;
    const discountPercent = selectedVariant?.discountPercent || product?.discountPercent || 0;

    if (isLoading)
        return (
            <div className={styles.container}>
                <Skeleton height="143px" width="160px" />
                <div className={styles.productInfo}>
                    <div className={styles.details}>
                        <Skeleton width="120px" />
                        <Skeleton width="180px" />
                    </div>
                    <Skeleton width="100px" />
                </div>
            </div>
        );

    if (!product) return null;

    const { stockInfo } = product;
    const isOOS = stockInfo?.code === 'out_of_stock';

    return (
        <div className={styles.container}>
            <div className={clsx(styles.img, { [styles.outOfStock]: isOOS })}>
                <ProductImage imageKey={imageUrl} size={IMAGE_SIZE.ML} altText={product.title || ''} />
            </div>

            <div className={styles.productInfo}>
                <div className={styles.details}>
                    <p className={styles.brand}>{product.brand}</p>
                    <p className={styles.title}>{product.title}</p>
                    {stockInfo && (
                        <span className={clsx(styles.stockLabel, stockInfo)} style={{ color: stockInfo.color }}>
                            {stockInfo.label}
                        </span>
                    )}
                </div>

                <ProductPrice
                    size="large"
                    price={normalPrice}
                    salePrice={salePrice}
                    discountPercent={discountPercent}
                    isVertical
                />
            </div>
        </div>
    );
};

export default HorizontalProductBox;
