import React from 'react';

import clsx from 'clsx';

import { TOption } from '@nm-namshi-frontend/core/types';

import styles from './TabsV2.module.scss';

type TProps<T> = {
    tabs: (TOption & { show: boolean })[];
    onTabSelect: (val: T) => void;
    selectedTab: T;
};

const Tabs = <T extends string>({ onTabSelect, selectedTab, tabs }: TProps<T>) => {
    if (!tabs.length) return null;

    return (
        <div className={styles.tabContainer}>
            {tabs
                .filter(({ show }) => !!show)
                .map((tab) => (
                    <div
                        className={clsx(styles.tabItem, selectedTab === tab.value && styles.selected)}
                        key={tab.value}
                        onClick={() => onTabSelect(tab.value)}
                    >
                        <span>{tab.label}</span>
                    </div>
                ))}
        </div>
    );
};

export default Tabs;
