import React from 'react';

import clsx from 'clsx';

import styles from './ProductBadge.module.scss';

export type TProductBadgeVariant = 'regular' | 'ribbon' | 'status' | 'tag';
type TProps = {
    variant?: TProductBadgeVariant;
    onPDP?: boolean;
    text: string;
};

const ProductBadge: React.FC<TProps> = ({ onPDP = false, text, variant = 'regular' }): JSX.Element => (
    <section
        className={clsx(
            styles.container,
            onPDP && styles.pdp,
            variant === 'ribbon' && styles.ribbon,
            variant === 'regular' && styles.regular,
            variant === 'status' && styles.status,
            variant === 'tag' && styles.tag,
        )}
    >
        {text}
    </section>
);

export default ProductBadge;
