import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ExtrasResponse, ProductFullResponse, ProductWishlistResponse } from '@nm-namshi-frontend/services';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { getProductPrice } from '@nm-namshi-frontend/core/utils/helper';

import ConversionCTA from './ConversionCTA';

type TProps = {
    size?: 'small' | 'medium';
    isLoading: boolean;
    extrasData?: ExtrasResponse;
    productData: ProductFullResponse | ProductWishlistResponse;
    selectedSku: string;
    onNoSizeSelected?: () => void;
    disabled?: boolean;
    onSuccess?: () => void;
    analyticsData: {
        eventLocation: TCartEventLocation;
        sizeSelected?: string;
    };
    pageType?: string;
};

const AddToBagButton: React.FC<TProps> = (props) => {
    const {
        analyticsData,
        disabled = false,
        extrasData,
        isLoading,
        onNoSizeSelected,
        onSuccess,
        pageType,
        productData,
        selectedSku,
        size = 'medium',
    } = props;

    const { t } = useTranslation('catalog');
    const { setAtbModalProduct } = useLayoutStore();

    const isUnDeliverable = !extrasData?.extras?.isDeliverable;
    const isDisabled = disabled || (!isLoading && (!productData.maxQty || isUnDeliverable));

    const selectedVariant = useMemo(
        () => productData.variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!productData.variants, selectedSku],
    );

    const price = getProductPrice(productData, selectedVariant);

    const isRocket = selectedVariant?.isRocket || productData.isRocket;
    const isGlobal = selectedVariant?.isGlobal || ('isGlobal' in productData && productData.isGlobal);

    return (
        <ConversionCTA
            size={size}
            analyticsData={{
                title: `${productData?.brand} ${productData?.title}`,
                parentSku: productData.parentSku,
                isRocket: !!isRocket,
                isGlobal,
                ...analyticsData,
            }}
            isFouponApplied={false}
            sku={selectedSku}
            price={price}
            isAddToBagDisabled={isDisabled}
            areAddToBagPrerequisitesLoading={isLoading}
            text={productData.stockInfo?.code === 'out_of_stock' ? t('out-of-stock') : t('add-to-bag-cta')}
            onNoSizeSelected={onNoSizeSelected}
            onSuccess={() => {
                setAtbModalProduct(productData, 'pdp');
                onSuccess?.();
            }}
            pageType={pageType}
        />
    );
};

export default AddToBagButton;
