import { useEffect, useState } from 'react';

import { clsx } from 'clsx';

import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import ProductCarousel from '@nm-namshi-frontend/core/page_components/shared/product/carousel/ProductCarousel';
import { RecommendationRequest } from '@nm-namshi-frontend/services/';
import { ProductBoxVariant } from '@nm-namshi-frontend/core/types';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';

import styles from './ProductRecommendations.module.scss';

type TProps = {
    title?: string;
    showTitle?: boolean;
    recoParams: RecommendationRequest;
    containerClassName?: string;
    variant?: ProductBoxVariant;
    analyticsData: {
        pageType: TPageType;
        pagePath?: string;
        widgetId?: number;
    };
};

const ProductRecommendations = ({
    analyticsData,
    containerClassName,
    recoParams,
    showTitle = true,
    title = '',
    variant,
}: TProps) => {
    const { excludeTags, placement, sku, tags } = recoParams;

    const [fetchOnClient, setFetchOnClient] = useState(false);
    const { data, isError, isLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_RECOMMENDATIONS, sku, placement],
        () =>
            getApiInstance().product.recommendations({
                requestBody: {
                    sku,
                    placement,
                    tags,
                    excludeTags,
                },
            }),
        {
            enabled: fetchOnClient,
        },
    );

    useEffect(() => {
        setFetchOnClient(true);
    }, []);

    if (isError) {
        return null;
    }

    return (
        <section className={clsx(styles.container, containerClassName)}>
            <ProductCarousel
                isLoading={isLoading}
                products={data?.products || []}
                title={showTitle ? title : ''}
                variant={variant}
                containerClass={styles.productCarouselContainer}
                analyticsData={{
                    pageType: analyticsData.pageType,
                    pagePath: analyticsData?.pagePath,
                    widgetId: analyticsData.widgetId,
                }}
                isRecos
            />
        </section>
    );
};

export default ProductRecommendations;
