import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { isEmpty, isString, isUndefined } from 'lodash';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import MobileWishlistProductBox from '@nm-namshi-frontend/schmatalog/components/wishlist/WishlistProductBox/MobileWishlistProductBox';
import DesktopWishlistProductBox from '@nm-namshi-frontend/bigalog/components/wishlist/DesktopWishlistProductBox/DesktopWishlistProductBox';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import MoreColorsControl from '@nm-namshi-frontend/core/page_components/shared/product/MoreColorsControl';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import QuickBuyButton from '@nm-namshi-frontend/core/page_components/shared/product/QuickBuyButton';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { ProductBoxVariant } from '@nm-namshi-frontend/core/types';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TCartEventLocation, TPageType, TTrackEvent } from '@nm-namshi-frontend/core/utils/analytics.types';
import { isTimerSaleActive } from '@nm-namshi-frontend/core/utils/helper';
import { getImageUrl, IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import {
    AdmonProductResponse,
    AnalyticsResponse,
    ProductFullResponse,
    ProductSimpleResponse,
    ProductSuggestion,
    ProductWishlistResponse,
    ProductWishlistResponse1,
} from '@nm-namshi-frontend/services/';
import { Timer } from '@nm-namshi-frontend/services/models/Timer';
import RotatingElements from '@nm-namshi-frontend/core/components/RotatingElements';
import DeliveryEstimateTag from '@nm-namshi-frontend/core/components/DeliveryEstimateTag';
import useWishlistSkus from '@nm-namshi-frontend/core/hooks/useWishlistSkus';
import SponsoredBadge from '@nm-namshi-frontend/core/page_components/productDetailPage/SponsoredBadge';
import RatingsBadge from '@nm-namshi-frontend/core/page_components/productDetailPage/RatingsBadge';

import ProductBadge from '../../productDetailPage/ProductBadge';
import AlteredLink from '../AlteredLink';
import ImagePixel from '../ImagePixel';
import ImageGalleryCarousel from './ImageGalleryCarousel';
import styles from './ProductBox.module.scss';
import ProductBoxFooter from './ProductBoxFooter';
import ProductDiscountTag from './ProductDiscountTag';
import TimerSale, { TimerSaleVariant } from './TimerSale';
import WishlistControl from './WishlistControl';
import NativeImageSlider from './NativeImageSlider';
import ProductIndicators from './ProductIndicators';
import QuickAddButton from './QuickAddButton';

type TProps = {
    product:
        | ProductFullResponse
        | ProductSimpleResponse
        | ProductSuggestion
        | ProductWishlistResponse
        | ProductWishlistResponse1 // SERVICE_LAYER: Temporary usage of this type until BE fixes openapi.json
        | AdmonProductResponse;
    variant?: ProductBoxVariant;
    onClick?: () => void;
    showWishlistIcon?: boolean;
    showQuickBuyIcon?: boolean;
    isWardrobeGuest?: boolean;
    isRecos?: boolean;
    showDiscountPercentage?: boolean;
    noImageCarousel?: boolean;
    oneImage?: boolean;
    analyticsData: {
        plpIdx?: string;
        plpPage?: number;
        pageType: TPageType;
        widgetId?: number;
        searchTerm?: string;
        attributionData?: AnalyticsResponse['td'];
        pagePath?: string;
        eventLocation?: TCartEventLocation;
    };
    textColor?: string;
    renderActions?: () => JSX.Element;
    outlined?: boolean;
};

type TProductBoxFull = {
    openQuickBuyModal: (arg0: ProductSimpleResponse) => void;
    product: ProductSimpleResponse;
    showQuickBuyIcon?: boolean;
    showWishlistIcon?: boolean;
    onClick?: () => void;
    showDiscountPercentage?: boolean;
    noImageCarousel?: boolean;
    oneImage?: boolean;
    analyticsData?: {
        plpIdx?: string;
        plpPage?: number;
        pageType: TPageType;
        widgetId?: number;
        searchTerm?: string;
        pagePath?: string;
        eventLocation?: TCartEventLocation;
        attributionData?: AnalyticsResponse['td'];
    };
    textColor?: string;
    isViewed: boolean;
};

type TSearchSuggestionProductBox = {
    product: ProductSuggestion | ProductSimpleResponse;
    onClick?: () => void;
    showWishlistIcon?: boolean;
    analyticsData?: {
        plpIdx?: string;
        plpPage?: number;
        pageType: TPageType;
        widgetId?: number;
        searchTerm?: string;
        attributionData?: AnalyticsResponse['td'];
        pagePath?: string;
    };
};

export type TWishlistProductBox = {
    product: ProductWishlistResponse;
    onClick?: () => void;
    variant?: ProductBoxVariant;
    doesWishlistBelongToCurrentUser?: boolean;
    showDiscountPercentage?: boolean;
    noImageCarousel?: boolean;
    renderActions?: () => JSX.Element;
    isWardrobeGuest?: boolean;
    analyticsData?: {
        pageType?: TPageType;
        pagePath?: string;
    };
};

const ProductBox = ({
    analyticsData,
    isRecos,
    isWardrobeGuest,
    noImageCarousel,
    onClick,
    oneImage,
    outlined = false,
    product,
    renderActions,
    showDiscountPercentage = false,
    showQuickBuyIcon = false,
    showWishlistIcon,
    textColor,
    variant = ProductBoxVariant.NORMAL,
}: TProps) => {
    const { isMobile } = useAppContext();
    const { setSelectedProductforQuickBuy } = useLayoutStore();
    const { setProductSponsored } = useCatalogStore();
    const productRef = useRef<HTMLDivElement>(null);

    const { isInView, isViewed, timesInView } = useIsInViewport({
        ref: productRef,
    });
    const { pageType, plpIdx, plpPage } = analyticsData;
    const getCommonAnalyticsPayload = (trackDataParams: any) => {
        const trackParams = trackDataParams;
        if (plpIdx && isString(plpIdx)) {
            const [contentRow, contentColumn] = plpIdx.split(':');
            trackParams.contentColumn = parseInt(contentColumn, 10);
            trackParams.contentRow = parseInt(contentRow, 10);
        }
        if ('imageKeys' in product && product.imageKeys?.[0]) {
            trackParams.imagePath = getImageUrl(product.imageKeys?.[0]);
        }
        if ('imageKey' in product) {
            trackParams.imagePath = getImageUrl(product.imageKey);
        }

        return trackParams;
    };

    // tracking
    useEffect(() => {
        const isWishlist = pageType === '/wishlist/allItems/' || pageType === '/wishlist/wardrobes/';

        if (isInView && timesInView === 1) {
            let trackParams: TTrackEvent['impression_detail']['data'] = {
                event: 'impression_detail',
                sku: product.sku,
                pageType,
                widgetId: isWishlist ? analyticsData.widgetId : undefined,
                contentCreative: isWishlist ? analyticsData?.widgetId : undefined,
                isRocket: product.isRocket as boolean,
                ...('isGlobal' in product ? { isGlobal: product.isGlobal as boolean } : {}),
                isSponsored: 'isSponsored' in product && product.isSponsored ? 1 : 0,
                ...('tags' in product && product.tags
                    ? {
                          productTag: {
                              ...(product.tags?.top_left
                                  ? { topLeftTag: Object.keys(product.tags.top_left)?.[0] }
                                  : {}),
                              ...('rotating' in product.tags && product.tags?.rotating
                                  ? { rotatingTag: Object.keys(product.tags.rotating) }
                                  : {}),
                          },
                      }
                    : {}),
                ...(plpPage ? { plpPage } : {}),
                ...(product.parentSku
                    ? {
                          parentSku: product.parentSku,
                      }
                    : {}),
                ...(analyticsData.pagePath
                    ? {
                          pagePath: analyticsData.pagePath,
                      }
                    : {}),
            };
            trackParams = getCommonAnalyticsPayload(trackParams);
            trackEvent(trackParams);
        }
    }, [isInView]);

    const handleProductBoxClick = () => {
        onClick?.();
        setProductSponsored('isSponsored' in product && product.isSponsored ? 1 : 0);

        if (isRecos) {
            let trackParams: TTrackEvent['recos_click']['data'] = {
                event: 'recos_click',
                sku: product.sku,
                ...(product.parentSku
                    ? {
                          parentSku: product.parentSku,
                      }
                    : {}),
                pageType,
            };
            trackParams = getCommonAnalyticsPayload(trackParams);
            trackEvent(trackParams);
        }
    };

    const boxContainer = !(variant === ProductBoxVariant.WISHLIST && !isMobile);

    return (
        <div
            ref={productRef}
            className={clsx(
                styles.container,
                boxContainer && styles.boxContainer,
                !isMobile && styles[variant],
                outlined && styles.outlined,
            )}
        >
            {variant === ProductBoxVariant.FULL && (
                <ProductBoxFull
                    showDiscountPercentage={showDiscountPercentage}
                    product={product as ProductSimpleResponse}
                    showQuickBuyIcon={showQuickBuyIcon}
                    showWishlistIcon={showWishlistIcon}
                    openQuickBuyModal={(prod) => {
                        setSelectedProductforQuickBuy(prod);
                    }}
                    oneImage={oneImage}
                    onClick={handleProductBoxClick}
                    analyticsData={analyticsData}
                    noImageCarousel={noImageCarousel}
                    textColor={textColor}
                    isViewed={isViewed}
                />
            )}
            {variant === ProductBoxVariant.SEARCHSUGGESTION && (
                <SearchSuggestionProductBox
                    product={product as ProductSuggestion}
                    onClick={handleProductBoxClick}
                    showWishlistIcon={showWishlistIcon}
                    analyticsData={analyticsData}
                />
            )}

            {variant === ProductBoxVariant.WISHLIST && isMobile && (
                <MobileWishlistProductBox
                    product={product as ProductWishlistResponse}
                    onClick={handleProductBoxClick}
                    noImageCarousel={noImageCarousel}
                    renderActions={renderActions}
                    isWardrobeGuest={isWardrobeGuest}
                    analyticsData={analyticsData}
                />
            )}

            {variant === ProductBoxVariant.WISHLIST && !isMobile && (
                <DesktopWishlistProductBox
                    product={product as ProductWishlistResponse}
                    onClick={handleProductBoxClick}
                    isWardrobeGuest={isWardrobeGuest}
                    noImageCarousel={noImageCarousel}
                />
            )}

            <ProductBoxFooter product={product as ProductSimpleResponse} />
        </div>
    );
};

const ProductBoxFull = ({
    analyticsData,
    isViewed,
    noImageCarousel,
    onClick,
    oneImage,
    openQuickBuyModal,
    product,
    showDiscountPercentage = false,
    showQuickBuyIcon,
    showWishlistIcon,
    textColor,
}: TProductBoxFull) => {
    const { isMobile } = useAppContext();
    const [activeParentSku, setActiveParentSku] = useState(''); // todo, this is never set, so lets remove this plus related
    const [sponsoredProductClicked, setSponsoredProductClicked] = useState(false);
    const { locale } = useAppPathname();
    const { lang } = getLocaleConfig(locale);
    const { setAttributionData, setPlpIndex, setPlpPage } = useCatalogStore();
    const isSwipeTracked = useRef(false);

    const {
        brand,
        discountPercent,
        discountTag,
        groupedProducts,
        hasGroupTag,
        imageKeys,
        normalPrice,
        parentSku,
        promise: deliveryPromise,
        reviewSummary,
        salePrice,
        sku,
        tags = {},
        timer,
        title,
    } = product;

    const doProductGroupsExist = !isEmpty(groupedProducts) && !isUndefined(groupedProducts);
    const isTimerActive = isTimerSaleActive(timer as Timer);

    const { checkIsInWishlist } = useWishlistSkus();

    const getQuickBuyAnalyticsPayload = () => {
        const analyticsPayload = {} as {
            contentRow?: number;
            contentColumn?: number;
            plpPage?: number;
        };

        if (analyticsData?.plpIdx && isString(analyticsData?.plpIdx)) {
            const [contentRow, contentColumn] = analyticsData.plpIdx.split(':');

            analyticsPayload.contentColumn = parseInt(contentColumn, 10);
            analyticsPayload.contentRow = parseInt(contentRow, 10);
        }

        if (analyticsData?.plpPage) {
            analyticsPayload.plpPage = analyticsData.plpPage;
        }

        // FYI - not setting attributionData for quick buy clicks from PLP, mainly because the feature is disabled
        // Might be something to think about for the future

        return analyticsPayload;
    };

    // Provides updated product information for display; applicable upon hover upon grouped products, if any
    const activeParentSkuData = useMemo(
        () =>
            doProductGroupsExist && activeParentSku
                ? groupedProducts.find(
                      ({ parentSku: groupedProductParentSku }) => groupedProductParentSku === activeParentSku,
                  )
                : null,
        [activeParentSku, doProductGroupsExist, groupedProducts],
    );

    // Callbacks

    // Renderers1
    const renderProductBadge = () => {
        const { top_left = {} } = tags;
        const tagFirstValue = Object.values(top_left)?.[0] as string;

        if (!tagFirstValue) return null;

        return (
            <div className={styles.productBadgeContainer}>
                <ProductBadge text={tagFirstValue} />
            </div>
        );
    };

    const renderWishlistIcon = () => (
        <WishlistControl
            analyticsData={{
                parentSku,
                price: activeParentSkuData
                    ? activeParentSkuData?.salePrice || activeParentSkuData.normalPrice
                    : product.salePrice || product.normalPrice,
                isRocket: product.isRocket as boolean,
                isGlobal: product.isGlobal as boolean,
            }}
            sku={activeParentSkuData?.sku || product.sku}
            isActive={checkIsInWishlist(parentSku)}
            parentSku={parentSku}
        />
    );

    const renderQuickAddButtn = () => (
        <QuickAddButton
            className={styles.quickbuyButton}
            product={product}
            analyticsData={{
                pageType: analyticsData?.pageType,
                pagePath: analyticsData?.pagePath,
                eventLocation: analyticsData?.eventLocation,
            }}
        />
    );

    /**
     * @deprecated Quick Add To Cart, Replaced By Size Selector
     * @returns Qucik Add To Cart
     */
    const renderQuickBuyButton = () => (
        <div className={styles.quickbuyButton}>
            <QuickBuyButton
                onClickQuickBuy={() => {
                    // In case a grouped product is being hovered on, send its uri, else send the root uri
                    openQuickBuyModal(product);
                    // tracking
                    trackEvent({
                        event: 'click_quick_buy',
                        sku,
                        parentSku,
                        ...getQuickBuyAnalyticsPayload(),
                    });
                }}
            />
        </div>
    );

    const renderTitleAndBrand = () => (
        <div style={{ color: textColor }}>
            <div className={styles.brand}>{brand}</div>
            <div className={styles.productTitle} title={title}>
                {title}
            </div>
        </div>
    );

    const renderProductImage = useCallback(() => {
        const imageSize = isMobile ? IMAGE_SIZE.ML : IMAGE_SIZE.L;
        let imageKeysToShow: string[] | undefined;

        if (!isEmpty(activeParentSku) && activeParentSkuData?.imageKey) {
            // Show only the groupedProduct image in carousel on hover of groupedProduct
            imageKeysToShow = [activeParentSkuData.imageKey];
        } else {
            imageKeysToShow = imageKeys;
        }

        if (!imageKeysToShow || isEmpty(imageKeysToShow)) {
            return <ProductImage altText={title || ''} imageKey="" size={imageSize} />;
        }

        if (imageKeysToShow?.length === 1) {
            return <ProductImage altText={title || ''} imageKey={imageKeysToShow[0]} size={imageSize} />;
        }

        const onSwipe = ({ imageKey }: { imageKey: string }) => {
            if (isSwipeTracked.current) return;

            const [contentRow, contentColumn] = analyticsData?.plpIdx?.split(':') || [undefined, undefined];
            const imagePath = getImageUrl(imageKey, imageSize || IMAGE_SIZE.ML);

            trackEvent({
                event: 'imageSwipe',
                sku: parentSku,
                imagePath,
                pageType: analyticsData?.pageType || 'listing',
                searchTerm: analyticsData?.searchTerm,
                contentColumn,
                contentRow,
                contentCreative: analyticsData?.widgetId,
            });

            isSwipeTracked.current = true;
        };

        if (isMobile) {
            const sliderData: { key: string; imageKey: string }[] = imageKeysToShow.map((imageKey) => ({
                key: imageKey,
                imageKey,
            }));
            return (
                <NativeImageSlider
                    lazyLoad
                    data={sliderData}
                    oneImage={oneImage}
                    onSwipe={(item) => onSwipe(item)}
                    renderBottomControls={({ currentSlide, slideCount }) => (
                        <ProductIndicators currentSlide={currentSlide} slideCount={slideCount} />
                    )}
                    renderSlide={(item) => (
                        <ProductImage size={imageSize} imageKey={item.imageKey} altText={title || ''} />
                    )}
                />
            );
        }

        return (
            <ImageGalleryCarousel
                variant="small"
                imageKeys={imageKeysToShow}
                isAutoPlayOnHoverEnabled
                isNavigationVisible={false}
                isZoomEnabled={false}
                imageSize={imageSize}
                oneImage={oneImage}
                noImageCarousel={noImageCarousel}
                altText={title || ''}
                onSwipe={onSwipe}
            />
        );
    }, [activeParentSku, activeParentSkuData, imageKeys, sku]);

    const renderPrice = () => {
        if (normalPrice) {
            return (
                <ProductPrice
                    price={normalPrice}
                    {...(salePrice ? { salePrice } : {})}
                    {...(discountPercent ? { discountPercent } : {})}
                    showDiscount={showDiscountPercentage}
                    size={isMobile ? 'regular' : 'large'}
                    priceStyleObj={{ old: { color: textColor } }}
                />
            );
        }

        return null;
    };

    const ratingsBadgeShift = isMobile ? 4 : 8;

    return (
        <AlteredLink
            locale={locale}
            onMouseLeave={() => setActiveParentSku('')}
            onClick={() => {
                onClick?.();
                if (analyticsData?.plpIdx) {
                    setPlpIndex(analyticsData?.plpIdx);
                }
                if (analyticsData?.plpPage) {
                    setPlpPage(analyticsData?.plpPage);
                }
                if (analyticsData?.attributionData) {
                    setAttributionData(analyticsData?.attributionData);
                }
                if ('isSponsored' in product && product.isSponsored) {
                    setSponsoredProductClicked(true);
                }
            }}
            href={`${locale}${product.uri}`}
            className={clsx(styles.container, styles.productBox)}
            prefetch
        >
            <div className={styles.imageContainer}>
                {isTimerActive ? (
                    <div className={styles.timerContainer}>
                        <TimerSale timerSaleConfig={timer as Timer} lang={lang} variant={TimerSaleVariant.tag} />
                    </div>
                ) : (
                    renderProductBadge()
                )}
                {'isSponsored' in product && product.isSponsored && (
                    <div className={styles.sponsoredBadgeContainer}>
                        <SponsoredBadge />
                    </div>
                )}
                {reviewSummary && (
                    <RatingsBadge
                        review={reviewSummary}
                        className={clsx({
                            [styles.shiftedRatings]: !oneImage && imageKeys && imageKeys.length > ratingsBadgeShift,
                        })}
                    />
                )}
                {isViewed && (
                    <>
                        <ImagePixel src={product.onViewBeacon} />
                        {sponsoredProductClicked && <ImagePixel src={product.onClickBeacon} />}
                    </>
                )}
                <div className={styles.imageActions}>
                    {showWishlistIcon && renderWishlistIcon()}
                    {hasGroupTag && (
                        <MoreColorsControl
                            productData={product}
                            analyticsData={{ pageType: analyticsData?.pageType, sku: product.sku }}
                        />
                    )}
                </div>
                {renderProductImage()}
                {discountTag && (
                    <div className={styles.discountTagWrapper}>
                        <ProductDiscountTag tag={discountTag} />
                    </div>
                )}
                {showQuickBuyIcon && renderQuickAddButtn()}
            </div>
            <div className={styles.detailsContainer}>
                <div className={styles.detailsHeader}>{renderTitleAndBrand()}</div>
                {renderPrice()}
                <RotatingElements elements={Object.values(tags?.rotating || {})} />
                {!!deliveryPromise && (
                    <DeliveryEstimateTag estimate={deliveryPromise} containerClass={styles.deliveryEstimateContainer} />
                )}
            </div>
        </AlteredLink>
    );
};

const SearchSuggestionProductBox = ({
    analyticsData,
    onClick,
    product,
    showWishlistIcon,
}: TSearchSuggestionProductBox) => {
    const {
        brand,
        discountPercent,
        discountTag,
        normalPrice,
        parentSku,
        salePrice,
        sku,
        tags = {},
        timer,
        title,
        uri,
    } = product;
    const { locale } = useAppPathname();

    const { lang } = getLocaleConfig(locale);
    const isTimerActive = isTimerSaleActive(timer as Timer);
    const { checkIsInWishlist } = useWishlistSkus();

    // Renderers
    const renderProductBadge = () => {
        const { top_left = {} } = tags;
        const tagFirstValue = Object.values(top_left)?.[0] as string;

        if (!tagFirstValue) return null;

        return (
            <div className={styles.productBadgeContainer}>
                <ProductBadge text={tagFirstValue} />
            </div>
        );
    };

    const renderTitleAndBrand = () => (
        <>
            <div className={styles.brand}>{brand}</div>
            <div className={styles.productTitle}>{title}</div>
        </>
    );

    const renderProductImage = () => {
        const imageSize = IMAGE_SIZE.ML;

        if ('imageKey' in product) {
            return <ProductImage altText={title || ''} imageKey={product.imageKey} size={imageSize} />;
        }
        if ('imageKeys' in product) {
            return <ProductImage altText={title || ''} imageKey={product.imageKeys?.[0]} size={imageSize} />;
        }

        return null;
    };

    const renderWishlistControl = () => (
        <WishlistControl
            analyticsData={{
                parentSku: product.parentSku,
                price: salePrice || normalPrice,
                isRocket: product.isRocket as boolean,
                isGlobal: 'isGlobal' in product && !!product.isGlobal,
                pageType: analyticsData?.pageType,
                pagePath: analyticsData?.pagePath,
            }}
            sku={sku}
            isActive={checkIsInWishlist(parentSku ?? '')}
            isVisibleWhenLoggedInOnly
            parentSku={parentSku ?? ''}
        />
    );

    return (
        <AlteredLink
            locale={locale}
            href={uri}
            className={clsx(styles.container, styles.productBox)}
            onClick={onClick}
            prefetch
        >
            <div className={styles.imageContainer}>
                {isTimerActive ? (
                    <div className={styles.timerContainer}>
                        <TimerSale timerSaleConfig={timer as Timer} lang={lang} variant={TimerSaleVariant.tag} />
                    </div>
                ) : (
                    renderProductBadge()
                )}
                {renderProductImage()}
                {'isSponsored' in product && product.isSponsored && (
                    <div className={styles.sponsoredBadgeContainer}>
                        <SponsoredBadge />
                    </div>
                )}
                {showWishlistIcon && <div className={styles.imageActions}>{renderWishlistControl()}</div>}
                {discountTag && (
                    <div className={styles.discountTagWrapper}>
                        <ProductDiscountTag tag={discountTag} />
                    </div>
                )}
            </div>
            <div className={styles.detailsContainer}>
                <div>{renderTitleAndBrand()}</div>
                <ProductPrice
                    salePrice={salePrice || 0}
                    price={normalPrice}
                    discountPercent={discountPercent}
                    showDiscount
                />
                {'promise' in product && !!product.promise && (
                    <DeliveryEstimateTag estimate={product.promise} containerClass={styles.deliveryEstimateContainer} />
                )}
            </div>
        </AlteredLink>
    );
};

export default ProductBox;
