import React, { CSSProperties, useMemo } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './GlobalTag.module.scss';
import Icon from './Icon';

type TProps = {
    size?: 'small' | 'normal';
};

const GlobalTag = ({ size = 'small' }: TProps) => {
    const { t } = useTranslation();
    const customeStyle: CSSProperties = {
        ...(size === 'normal' ? { fontSize: 14 } : {}),
    };

    const iconSize = useMemo(() => {
        switch (size) {
            case 'small':
                return 16;
            case 'normal':
            default:
                return 18;
        }
    }, [size]);

    return (
        <div className={clsx(styles.container)} style={customeStyle}>
            <div className={styles.bg} />
            <div className={styles.content}>
                <Icon name="plane" size={iconSize} />
                <span> {t('global')}</span>
            </div>
        </div>
    );
};
export default GlobalTag;
