import React from 'react';

import styles from './Table.module.scss';

export type TRows = {
    id: string;
    name?: string;
    cells?: string[];
    customSelectedStyles: string;
    icon?: React.ReactNode;
};

type TProps = {
    headers?: (string | JSX.Element)[];
    rows: TRows[];
    onRowClick: (val: string) => void;
    renderRow?: (row: TRows) => JSX.Element;
};

const Table: React.FC<TProps> = ({ headers, onRowClick, renderRow, rows }) => (
    <div className={styles.wrapper}>
        <table>
            {headers && (
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header.toString()}>{header}</th>
                        ))}
                    </tr>
                </thead>
            )}
            <tbody>
                {rows.map((row, index) => (
                    <tr
                        // eslint-disable-next-line react/no-array-index-key
                        key={index + row.id}
                        onClick={() => onRowClick(row.id)}
                        className={row.customSelectedStyles || ''}
                    >
                        {renderRow
                            ? renderRow(row)
                            : (row.cells as string[])?.map((cell, idx) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <td key={idx + cell}>
                                      <div style={{ display: 'flex', justifyContent: 'center', columnGap: '10px' }}>
                                          {cell} {idx === 0 && row.icon && <span>{row.icon}</span>}
                                      </div>
                                  </td>
                              ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default Table;
