/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';

import clsx from 'clsx';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './RotatingElements.module.scss';

type TProps = { elements: Array<ReactNode>; className?: string; frameHeight?: number };

const MAX_TO_SHOW = 5;

const RotatingElements: React.FC<TProps> = ({ className, elements, frameHeight = 16 }) => {
    if (elements.length < 1) return null;

    if (elements.length === 1) {
        return (
            <div className={clsx(styles.container, className)}>
                <div>{elements[0]}</div>
            </div>
        );
    }

    const customStyles = {
        '--frame-height': `${frameHeight}px`,
        '--offset-frame-height': `-${frameHeight}px`,
    } as React.CSSProperties;

    return (
        <div className={clsx(styles.container, className)} style={customStyles}>
            <div className={clsx(styles.animation, styles[`animationRotate${elements.length}`])}>
                {elements.slice(0, MAX_TO_SHOW).map((nudge, index) => (
                    <div key={`${nudge}-${index}`}>{nudge}</div>
                ))}
            </div>
            <div className={clsx(styles.animation, styles[`animationRotateDupe${elements.length}`])}>
                {elements.slice(0, MAX_TO_SHOW).map((nudge, index) => (
                    <div key={`dupe-${nudge}-${index}`}>{nudge}</div>
                ))}
            </div>
        </div>
    );
};
export default RotatingElements;
