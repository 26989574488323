import { create } from 'zustand';

import {
    AdmonProductResponse,
    AnalyticsResponse,
    ApiError,
    CatalogResponse,
    ExtrasResponse,
    ProductSimpleResponse,
} from '@nm-namshi-frontend/services';
// import useCategoriesStore from '@nm-namshi-frontend/core/stores/useCategoriesStore';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { TPageSource } from '@nm-namshi-frontend/core/utils/analytics.types';

type TEditSizesModal = { show: boolean; onSuccess?: () => void; isRefreshing?: boolean };

interface ICatalogStore {
    catalog: CatalogResponse | null;
    isLoadingCatalog: boolean;
    errorCatalog: ApiError | null;
    // fetchCatalog: (uri: string) => Promise<void>;

    catalogExtras: ExtrasResponse | null;
    isLoadingCatalogExtras: boolean;
    errorCatalogExtras: ApiError | null;
    fetchCatalogExtras: (uri: string) => Promise<void>;

    getProductListForUrl: (productsUrl: string) => Promise<ProductSimpleResponse[] | AdmonProductResponse[]>;

    // plp_index ie: analytics relevant to track the row/col index of a ProductBox, when a PDP is reached from PLP
    plpIndex: string;
    setPlpIndex: (plpIndex: string) => void;

    // plpPage ie: analytics relevant to track the page number of a ProductBox, when a PDP is reached from PLP
    plpPage: number | null;
    setPlpPage: (plpPage: number | null) => void;

    pageType: string | null;
    setPageType: (pageType: string | null) => void;

    // Token data that is part of the analytics object in /catalog response(usually for PLP's)
    // This will be stored and reported in page_pdp NAT events for any product clicked within the PLP
    attributionData: AnalyticsResponse['td'];
    setAttributionData: (data: AnalyticsResponse['td']) => void;

    // to track the current bottom tab and send it along with page event
    pageSource?: TPageSource;
    setPageSource: (val?: TPageSource) => void;

    // to track if product sponsored from pdp -> by setting it in plp and call it in pdp
    isProductSponsored: 0 | 1;
    setProductSponsored: (val: 0 | 1) => void;

    editSizesModal: TEditSizesModal;
    setEditSizesModal: (val: TEditSizesModal) => void;
}

const useCatalogStore = create<ICatalogStore>((set, get) => ({
    catalog: null,
    isLoadingCatalog: false,
    errorCatalog: null,
    // Not being used as of now -
    // @TODO : Ehab - Plan the removal as part of future cleanup
    // fetchCatalog: async (uri) => {
    //     set({ isLoadingCatalog: true });
    //     try {
    //         const catalogUri = uri === '/' ? useCategoriesStore.getState().rootCategory : uri;

    //         const catalogResponse = await getApiInstance().product.catalog({
    //             requestBody: { uri: catalogUri },
    //         });
    //         set({ catalog: catalogResponse, isLoadingCatalog: false, errorCatalog: null });
    //         get().fetchCatalogExtras(uri);
    //     } catch (error) {
    //         set({ catalog: null, isLoadingCatalog: false, errorCatalog: error as unknown as ApiError });
    //     }
    // },

    catalogExtras: null,
    isLoadingCatalogExtras: false,
    errorCatalogExtras: null,
    fetchCatalogExtras: async (uri) => {
        set({ isLoadingCatalogExtras: true });
        try {
            const catalogExtras = await getApiInstance().extras.catalogExtras({
                requestBody: { uri: encodeURI(uri) },
            });
            set({ catalogExtras, isLoadingCatalogExtras: false, errorCatalogExtras: null });
        } catch (error) {
            set({
                catalogExtras: null,
                isLoadingCatalogExtras: false,
                errorCatalogExtras: error as unknown as ApiError,
            });
        }
    },

    getProductListForUrl: async (productsUrl: string) => {
        try {
            const productsResponse = await getApiInstance().product.products({
                requestBody: {
                    uri: productsUrl,
                },
            });
            return productsResponse?.products ?? [];
        } catch (error) {
            return [];
        }
    },

    plpIndex: '',
    setPlpIndex: (plpIndex: string) => {
        set({ plpIndex });
    },

    plpPage: null,
    setPlpPage: (plpPage: number | null) => {
        set({ plpPage });
    },

    pageType: null,
    setPageType: (pageType: string | null) => {
        set({ pageType });
    },

    attributionData: null,
    setAttributionData: (attributionData: AnalyticsResponse['td']) => {
        set({ attributionData });
    },

    pageSource: undefined,
    setPageSource: (pageSource?: TPageSource) => {
        set({ pageSource });
    },

    isProductSponsored: 0,
    setProductSponsored: (isProductSponsored: 0 | 1) => {
        set({ isProductSponsored });
    },

    editSizesModal: { show: false },
    setEditSizesModal: (editSizesModal: TEditSizesModal) => {
        set({ editSizesModal });
    },
}));

export default useCatalogStore;
