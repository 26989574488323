import React, { useMemo } from 'react';

import clsx from 'clsx';

import { ProductDeliveryEstimate } from '@nm-namshi-frontend/services/models/ProductDeliveryEstimate';

import styles from './DeliveryEstimateTag.module.scss';
import Icon from './Icon';
import GlobalTag from './GlobalTag';

type TProps = {
    containerClass?: string;
    estimate?: ProductDeliveryEstimate;
};

const DeliveryEstimateTag: React.FC<TProps> = ({ containerClass, estimate }) => {
    const deliveryTypeTag = useMemo(() => {
        if (estimate?.isGlobal) return <GlobalTag />;
        return null;
    }, [estimate]);

    if (!estimate?.estimate) return null;

    return (
        <div
            className={clsx(containerClass, styles.flexWrapper, {
                [styles.global]: estimate.isGlobal,
            })}
        >
            <div className={clsx(styles.container)}>
                {estimate.text}
                {estimate.isHighlight ? (
                    <div className={styles.highlight}>
                        <div className={styles.bg} />
                        <div className={styles.content}>
                            {estimate.isRocket && <Icon name="rocketPlain" size={8} className={styles.estimateIcon} />}
                            {estimate.estimate}
                        </div>
                    </div>
                ) : (
                    ` ${estimate.estimate}`
                )}
            </div>
            {deliveryTypeTag}
        </div>
    );
};

export default DeliveryEstimateTag;
