import React from 'react';

import styles from './HeartIcon.module.scss';
import Loader from './loaders/Loader';

type THeartIconProps = {
    active: boolean;
    onClick?: () => void;
    isLoading?: boolean;
    color?: string;
    size?: number;
};

const HeartIcon: React.FC<THeartIconProps> = (props) => {
    const { active, color = 'black', isLoading = false, onClick, size = 20 } = props;

    // Renderer
    const renderContent = () => {
        if (isLoading) return <Loader size={size} color={color} />;

        return <HeartIconSvg filled={active} size={size} color={color} />;
    };

    // Not a button in this case
    if (!onClick) {
        return <span className={styles.btn}>{renderContent()}</span>;
    }

    return (
        <button
            type="button"
            className={styles.btn}
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
            disabled={isLoading}
        >
            {renderContent()}
        </button>
    );
};

const HeartIconSvg: React.FC<{ color?: string; size?: number; filled?: boolean }> = ({
    color = 'black',
    filled = false,
    size = 20,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={filled ? color : 'none'}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.2161 18.9593L11.2146 18.958C8.61966 16.6498 6.54731 14.8014 5.112 13.0789C3.68704 11.3688 3 9.90904 3 8.3951C3 5.93556 4.95284 4 7.5 4C8.94478 4 10.3427 4.66486 11.2474 5.69881L12 6.55896L12.7526 5.69881C13.6573 4.66486 15.0552 4 16.5 4C19.0472 4 21 5.93556 21 8.3951C21 9.90904 20.313 11.3688 18.888 13.0789C17.4527 14.8014 15.3803 16.6498 12.7854 18.958L12.7839 18.9593L12 19.6593L11.2161 18.9593Z"
            stroke={color}
            strokeWidth="2"
        />
    </svg>
);

export default HeartIcon;
