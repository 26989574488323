import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { WISHLIST_PARENTSKUS_FETCH_STALETIME } from '@nm-namshi-frontend/core/config';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';

const useWishlistSkus = () => {
    const { data, isFetched, isLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS],
        () => getApiInstance().wardrobe.getWishlistParentSkus({}),
        {
            staleTime: WISHLIST_PARENTSKUS_FETCH_STALETIME,
        },
    );

    const checkIsInWishlist = (parentSku: string) => (data?.parentSkus || []).includes(parentSku);

    return {
        wishlistParentSkus: data?.parentSkus ?? [],
        isWishlistFetched: isFetched,
        isWishlistLoading: isLoading,
        checkIsInWishlist,
    };
};

export default useWishlistSkus;
