// this isn't a next image as its a fluid image dependent on productbox width

import React from 'react';

import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import getImages from '@nm-namshi-frontend/core/utils/images';
import IconButton from '@nm-namshi-frontend/core/components/IconButton';
import { ProductSimpleResponse } from '@nm-namshi-frontend/services';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';

type TProps = {
    productData: ProductSimpleResponse;
    analyticsData: {
        pageType?: TPageType;
        sku: string;
    };
};

const MoreColorsControl: React.FC<TProps> = ({ analyticsData, productData }) => {
    const { t } = useTranslation('common');
    const moreColorsSrc = get(getImages(), 'misc.moreColors', '');

    const setGroupedProductsModal = useLayoutStore((store) => store.setGroupedProductsModal);

    return (
        <IconButton
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setGroupedProductsModal({
                    show: true,
                    product: productData,
                    widgetId: WIDGET_IDS.PRODUCT_OPTIONS_MODAL_PRODUCT_BOX,
                });
                trackEvent({
                    event: 'genericButtonClick',
                    action: 'colorGroupClick',
                    ...analyticsData,
                });
            }}
        >
            <img src={moreColorsSrc} style={{ width: '100px' }} alt="More Colors" title={t('more-colors')} />
        </IconButton>
    );
};

export default MoreColorsControl;
