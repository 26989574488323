import { TLocaleSEO, NavDepartment, NavDepartmentsResponse, NavSubdepartment } from '@nm-namshi-frontend/core/types';

import { slashifyUrl } from './url';

export const getMatchingDepartmentData = ({
    departmentId,
    departments,
}: {
    departments: Array<NavDepartment>;
    departmentId: string;
}): NavDepartment | null => {
    if (!departments.length || !departmentId) return null;

    const matchingDepartmentData = departments.find(({ id }) => id === departmentId);

    return matchingDepartmentData || null;
};

const getMatchingSubDepartmentData = ({
    subDepartmentId,
    subDepartments,
}: {
    subDepartmentId: string;
    subDepartments: Array<NavSubdepartment>;
}): NavSubdepartment | null => {
    if (!subDepartments.length || !subDepartmentId) return null;

    const matchingSubDepartmentData = subDepartments.find(({ id }) => id === subDepartmentId);

    return matchingSubDepartmentData || null;
};

export const checkSubDepartmentExistsInDepartment = ({
    navigationData,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: {
    navigationData?: NavDepartmentsResponse | null;
    selectedDepartmentId: string;
    selectedSubDepartmentId: string;
}): boolean => {
    if (!navigationData || 'error' in navigationData || !navigationData.departments.length) return false;

    const selectedDepartmentData = getMatchingDepartmentData({
        departmentId: selectedDepartmentId,
        departments: navigationData.departments,
    });

    if (!selectedDepartmentData || !selectedDepartmentData.subDepartments?.length) return false;

    const matchingSubDepartmentData = getMatchingSubDepartmentData({
        subDepartmentId: selectedSubDepartmentId,
        subDepartments: selectedDepartmentData.subDepartments,
    });

    // If a matching subDepartment is found, the return will be true, else false
    return !!matchingSubDepartmentData;
};

// This function is used to get granular department/subDepartment information, based on incoming ID's
// If there is a selected subDepartment - return its data ONLY if it is part of the currently selected department
// If not, just return the selected department data
export const getSelectedDepartmentOrSubDepartmentData = ({
    navigationData,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: {
    navigationData?: NavDepartmentsResponse | { error: string; status: number };
    selectedDepartmentId: string;
    selectedSubDepartmentId: string;
}): NavDepartment | NavSubdepartment | null => {
    if (!navigationData || 'error' in navigationData) return null;

    const selectedDepartmentData = getMatchingDepartmentData({
        departmentId: selectedDepartmentId,
        departments: navigationData.departments,
    });

    // The logic:
    // If the selectedSubDepartmentId exists within selectedDepartmentData (eg. fashion-men in fashion) - return subDepartmentData
    // If not(eg. fashion-men does not exist within beauty), return selectedDepartmentData
    if (selectedDepartmentData) {
        // Return subDepartmentData, IF selectedSubDepartmentId is present in selectedDepartmentData...
        if (!!selectedDepartmentData?.subDepartments && selectedDepartmentData.subDepartments.length) {
            const matchingSubDepartmentData = getMatchingSubDepartmentData({
                subDepartmentId: selectedSubDepartmentId,
                subDepartments: selectedDepartmentData.subDepartments,
            });

            if (matchingSubDepartmentData) {
                return matchingSubDepartmentData;
            }
        }
        // ... if not, return selectedDepartmentData
        return selectedDepartmentData;
    }

    return null;
};

export const getDefaultDepartmentUri = ({
    locale,
    navigationData,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: {
    locale?: TLocaleSEO;
    navigationData?: NavDepartmentsResponse | { error: string; status: number } | null;
    selectedDepartmentId?: string;
    selectedSubDepartmentId?: string;
}): string => {
    if (
        !navigationData ||
        'error' in navigationData ||
        !('departments' in navigationData) ||
        !navigationData?.departments?.length
    ) {
        // Basic error scenario
        return '';
    }
    let defaultDepartmentUri = '';

    if (selectedDepartmentId) {
        // Find the departmentData for selectedDepartmentId
        const matchingDepartmentData = getMatchingDepartmentData({
            departments: navigationData.departments,
            departmentId: selectedDepartmentId,
        });

        if (matchingDepartmentData) {
            // If there was a previously selected subDepartment
            if (selectedSubDepartmentId && !!matchingDepartmentData.subDepartments?.length) {
                // Find the subDepartmentData for selectedSubDepartmentId
                const matchingSubDepartmentData = getMatchingSubDepartmentData({
                    subDepartmentId: selectedSubDepartmentId,
                    subDepartments: matchingDepartmentData.subDepartments,
                });

                // If matching subDepartment was found
                if (matchingSubDepartmentData && 'uri' in matchingSubDepartmentData) {
                    defaultDepartmentUri = matchingSubDepartmentData.uri;
                } else if (matchingDepartmentData?.uri) {
                    // Fallback to department uri
                    defaultDepartmentUri = matchingDepartmentData.uri;
                }
            } else if (matchingDepartmentData?.uri) {
                // Fallback to department uri
                defaultDepartmentUri = matchingDepartmentData.uri;
            }
        }
    }

    // defaultDepartmentUri contains a leading slash and leading slashs as it comes from BE response
    if (defaultDepartmentUri) {
        if (locale) {
            return `/${locale}${defaultDepartmentUri}`;
        }

        return defaultDepartmentUri;
    }

    return '';
};

// For every catalog url - get corresponding navigation-categories data
// Useful to determine the selected department and subdepartment on the server
// NOTE: This is relevant for catalog pages only
export const getSelectedSubDepartmentIdForCatalogUri = ({
    catalogUri,
    navigationData,
    selectedDepartmentId,
}: {
    catalogUri: string;
    navigationData: NavDepartmentsResponse | { error: string; status: number };
    selectedDepartmentId: string;
}) => {
    if (!catalogUri) return null;

    const slashifiedCatalogUri = slashifyUrl(catalogUri, true, true);

    const slashifiedCatalogUriWithoutQueryParams = slashifiedCatalogUri.split('?')[0];

    let selectedSubDepartmentId = null;

    if (!('error' in navigationData)) {
        const { departments } = navigationData;
        const matchingDepartmentData = getMatchingDepartmentData({ departments, departmentId: selectedDepartmentId });

        if (
            !!matchingDepartmentData &&
            'subDepartments' in matchingDepartmentData &&
            !!matchingDepartmentData?.subDepartments?.length
        ) {
            const { subDepartments } = matchingDepartmentData;
            const matchingSubDepartmentDataFromCatalogUri = subDepartments?.find(
                // In case search query params are present as subDepartment uri, this logic will handle that too
                ({ uri }) => {
                    const slashifiedCurrentUri = slashifyUrl(uri, true, true);
                    return (
                        slashifiedCurrentUri === slashifiedCatalogUri ||
                        slashifiedCurrentUri === slashifiedCatalogUriWithoutQueryParams
                    );
                },
            );

            if (!!matchingSubDepartmentDataFromCatalogUri && 'id' in matchingSubDepartmentDataFromCatalogUri) {
                selectedSubDepartmentId = matchingSubDepartmentDataFromCatalogUri.id;
            }
        }
    }

    return selectedSubDepartmentId;
};
