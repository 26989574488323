/**
 * Support for max number of items visible
 * Support for recording impression when the item comes into view
 * Support for auto scroll
 * Support for loop
 * Support for defineing the productBox variant
 * Support for showing pagination
 * Support for showing carousel buttons for navigation
 */

import { isEmpty } from 'lodash';
import Carousel from 'nuka-carousel/packages/nuka/lib/carousel';
import clsx from 'clsx';

import { AdmonProductResponse, ProductSimpleResponse, ProductSuggestion } from '@nm-namshi-frontend/services/';
import { ProductBoxVariant } from '@nm-namshi-frontend/core/types';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import ProductSkeleton from '@nm-namshi-frontend/core/components/loaders/ProductSkeleton';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './ProductCarousel.module.scss';
import ProductBox from '../ProductBox';

type TProps = {
    analyticsData: {
        widgetId?: number;
        pageType: TPageType;
        pagePath?: string;
    };
    products: Array<ProductSimpleResponse> | Array<ProductSuggestion> | Array<AdmonProductResponse>;
    showDiscountPercentage?: boolean;
    title?: string;
    titleClass?: string;
    variant?: ProductBoxVariant;
    containerClass?: string;
    customCarouselStyles?: string;
    isLoading?: boolean;
    isRecos?: boolean;
    textColor?: string;
    onProductBoxClick?: () => void;
};

const ProductCarousel = ({
    analyticsData,
    containerClass,
    customCarouselStyles,
    isLoading,
    isRecos,
    onProductBoxClick,
    products,
    showDiscountPercentage = true,
    textColor,
    title,
    titleClass = '',
    variant,
}: TProps) => {
    const { locale } = useAppPathname();
    const { isMobile } = useAppContext();
    const { isArabic } = getLocaleConfig(locale);

    // If no incoming variant, decide based on screen size
    const variantToShow = variant || ProductBoxVariant.FULL;

    let slideCount;

    if (isMobile) {
        slideCount = 3;
    } else {
        slideCount = 5;
    }

    const renderProduct = (
        product: ProductSimpleResponse | ProductSuggestion | AdmonProductResponse,
        index: number,
    ) => (
        <div key={product.sku} className={styles.productWrapper}>
            <ProductBox
                product={product}
                oneImage
                variant={variantToShow}
                showWishlistIcon
                analyticsData={{
                    widgetId: analyticsData?.widgetId,
                    plpIdx: `0:${index}`,
                    pageType: analyticsData?.pageType,
                    pagePath: analyticsData?.pagePath,
                }}
                showDiscountPercentage={showDiscountPercentage}
                noImageCarousel={!!isMobile}
                isRecos={isRecos}
                textColor={textColor}
                onClick={onProductBoxClick}
                outlined={isMobile}
            />
        </div>
    );

    if (isLoading) {
        return (
            <div
                className={clsx(
                    isMobile ? styles.mobileCarouselContainer : styles.carouselContainer,
                    containerClass,
                    isMobile && styles.preventOverflow,
                    styles.loading,
                )}
            >
                {title && <Skeleton width="150px" height="30px" className={styles.title} />}
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${Math.ceil(slideCount)} , 1fr)`,
                        gap: '10px',
                    }}
                >
                    {[...Array(Math.ceil(slideCount))].map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <ProductSkeleton key={`Skeleton-${index}`} />
                    ))}
                </div>
            </div>
        );
    }

    if (isEmpty(products)) return null;

    if (isMobile) {
        return (
            <div className={clsx(styles.mobileCarouselContainer, containerClass)}>
                <>
                    {!!title && <h2 className={clsx(styles.title, titleClass)}>{title}</h2>}
                    <div className={styles.carousel}>{products.map(renderProduct)}</div>
                </>
            </div>
        );
    }

    return (
        <div className={clsx(styles.carouselContainer, containerClass)}>
            <>
                {!!title && <h2 className={clsx(styles.title, titleClass)}>{title}</h2>}
                <Carousel
                    isRtl={isArabic}
                    slidesToShow={slideCount}
                    slidesToScroll={slideCount}
                    speed={250}
                    dragging={isMobile}
                    cellSpacing={4}
                    renderBottomCenterControls={() => null}
                    className={customCarouselStyles}
                    renderCenterLeftControls={
                        !isMobile && slideCount < products.length
                            ? ({ previousDisabled, previousSlide }) => (
                                  <button
                                      type="button"
                                      onClick={previousSlide}
                                      disabled={previousDisabled}
                                      className={clsx(styles.buttonContainer, previousDisabled && styles.disabled)}
                                  >
                                      <Icon name="chevronBack" />
                                  </button>
                              )
                            : null
                    }
                    renderCenterRightControls={
                        !isMobile && slideCount < products.length
                            ? ({ nextDisabled, nextSlide }) => (
                                  <button
                                      type="button"
                                      onClick={nextSlide}
                                      disabled={nextDisabled}
                                      className={clsx(styles.buttonContainer, nextDisabled && styles.disabled)}
                                  >
                                      <Icon name="chevronForward" size={21} />
                                  </button>
                              )
                            : null
                    }
                >
                    {products.map(renderProduct)}
                </Carousel>
            </>
        </div>
    );
};

export default ProductCarousel;
