import React, { useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import Loader from '@nm-namshi-frontend/core/components/loaders/Loader';
import { ProductFullResponse, ProductSimpleResponse } from '@nm-namshi-frontend/services';
import useQuickSizeSelect from '@nm-namshi-frontend/core/hooks/useQuickSizeSelect';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';

import styles from './QuickAddButton.module.scss';
import QuickSizeSelector from './QuickSizeSelector';

type TProps = {
    product: ProductSimpleResponse;
    analyticsData: {
        eventLocation?: TCartEventLocation;
        pageType?: string;
        pagePath?: string;
    };
    className: string;
};

const QuickAddButton = ({ analyticsData, className, product }: TProps) => {
    const [fullProductDataForSizeSelect, setFullProductDataForSizeSelect] = useState<ProductFullResponse | null>(null);
    const { t } = useTranslation('catalog');
    const { isAddToBagLoading, isFetchigFullProduct, setSelectedVariant, startQuickSizeSelect } = useQuickSizeSelect({
        product,
        setFullProductDataForSizeSelect,
        analyticsData,
    });

    return (
        <>
            <button
                type="button"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    startQuickSizeSelect();
                }}
                className={clsx(styles.iconContainer, className)}
            >
                {isAddToBagLoading || isFetchigFullProduct ? (
                    <Loader color="var(--clr-dark)" size={18} />
                ) : (
                    <Icon name="qucikAddToBag" color="offBlack" size={20} />
                )}
            </button>
            {!!fullProductDataForSizeSelect && (
                <QuickSizeSelector
                    productData={fullProductDataForSizeSelect}
                    isLoading={isAddToBagLoading}
                    isOpen={!!fullProductDataForSizeSelect}
                    onClose={() => setFullProductDataForSizeSelect(null)}
                    ctaText={t('add-to-bag-cta')}
                    onSelect={setSelectedVariant}
                />
            )}
        </>
    );
};

export default QuickAddButton;
