import React, { useMemo } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useCatalogStore from '@nm-namshi-frontend/core/stores/useCatalogStore';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';
import useParamState from '@nm-namshi-frontend/core/hooks/useParamState';
import { ProductWishlistResponse } from '@nm-namshi-frontend/services';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';

import { getApiInstance } from '../../../../api';
import { REACT_QUERY_KEYS } from '../../../../constants/reactQueryKeys';
import Button from '../../../../components/Button';
import { trackEvent } from '../../../../utils/analytics';

type TProps = {
    product: ProductWishlistResponse;
    selectedSku: string;
    onNoSizeSelected?: () => void;
    onSuccess: () => void;
    analyticsData: {
        eventLocation: TCartEventLocation;
        sizeSelected?: string;
    };
    size?: 'small' | 'medium';
};

const MoveToBag = ({ analyticsData, onNoSizeSelected, onSuccess, product, selectedSku, size = 'medium' }: TProps) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation('wishlist');
    const { locale } = useAppPathname();
    const { currencyCode, currencyCodeEN } = getLocaleConfig(locale);
    const { conversionRates } = useConfigContext();
    const { setAtbModalProduct } = useLayoutStore();
    const { isProductSponsored } = useCatalogStore();

    const [currentPage = 1] = useParamState('page');

    const { isLoading: isDeleteWishlistItemLoading, mutate: wishlistDeleteItem } = useMutation(
        () =>
            getApiInstance().wardrobe.deleteItem({
                parentSku: product.parentSku as string,
            }),
        {
            onSuccess: () => {
                // when removing from wardrobe, ensure that the item is also removed from the wishlist
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS, currentPage]);
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_SINGLE_WARDROBE_DATA]);

                return queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
            },
            onError: (error: string) => {
                toast.error(error.toString());
            },
        },
    );
    const { isLoading: isMoveToBagLoading, mutate: moveItemToBag } = useMutation(
        () =>
            getApiInstance().session.addItem({
                requestBody: {
                    sku: selectedSku,
                    qty: 1,
                },
            }),
        {
            onSuccess: () => {
                trackMoveToBag();
                wishlistDeleteItem();
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_ACTIVE_SESSION]);
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
                setAtbModalProduct(product, 'wishlist');
                onSuccess();
            },
            onError: (error: any) => {
                toast.error(error?.body?.error || error.toString());
            },
        },
    );

    const selectedSkuData = useMemo(() => {
        if (product.variants?.length) {
            const { variants } = product;
            const data = variants.find(({ sku }) => sku === selectedSku);

            if (data) return data;
        }

        // Fallback to parent product data
        return product;
    }, [product, selectedSku]);

    const trackMoveToBag = () => {
        const convertedPrice = convertPrice({
            conversionRates,
            price: product.salePrice || product.normalPrice,
            toCurrency: currencyCode,
        });

        trackEvent({
            event: 'cart_add',
            sku: product.sku,
            parentSku: product.parentSku,
            price: convertedPrice,
            value: convertedPrice, // Since always single qty is added to cart, value = price
            quantity: 1, // Always adding single qty to cart
            currency: currencyCodeEN,
            title: product.title,
            event_location: analyticsData.eventLocation,
            sizeSelected: analyticsData.sizeSelected,
            isRocket: selectedSkuData.isRocket,
            ...('isGlobal' in selectedSkuData ? { isGlobal: selectedSkuData.isGlobal } : {}),
            isSponsored: isProductSponsored,
        });
    };

    const moveItem = () => {
        if (!selectedSku) {
            onNoSizeSelected?.();
            return;
        }

        moveItemToBag();
    };

    return (
        <Button
            size={size}
            onClick={moveItem}
            disabled={product.stockInfo?.code === 'out_of_stock'}
            variant="tertiary"
            loading={isMoveToBagLoading || isDeleteWishlistItemLoading}
        >
            {product.stockInfo?.code === 'out_of_stock' ? t('common.out-of-stock') : t('common.bag')}
        </Button>
    );
};

export default MoveToBag;
