import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import {
    CatalogDetailsResponse,
    ProductFullResponse,
    ProductSimpleResponse,
    ProductVariant,
} from '@nm-namshi-frontend/services';
import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getProductPrice, isOneSize } from '@nm-namshi-frontend/core/utils/helper';
import { TCartEventLocation } from '@nm-namshi-frontend/core/utils/analytics.types';

import useAddToBag from './useAddToBag';

type TProps = {
    product: ProductSimpleResponse;
    setFullProductDataForSizeSelect: (product: ProductFullResponse | null) => void;
    analyticsData: {
        eventLocation?: TCartEventLocation;
        pageType?: string;
        pagePath?: string;
    };
};

/**
 * Enabling Quick Buy using size selector from recommendations
 *
 * Because Recommendations doesn't have variants data, the flow is:
 * - Fetch Full Product Details to have variants
 * - Open size selector for non OS items
 *
 * Enhancements required to include variants in recos response
 */

const useQuickSizeSelect = ({ analyticsData, product, setFullProductDataForSizeSelect }: TProps) => {
    const { uri } = product;

    const { t } = useTranslation();

    const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>();

    const { isLoading: isFetchigFullProduct, mutate: fetchFullProduct } = useMutation(
        [REACT_QUERY_KEYS.GET_PRODUCT_DETAILS, uri],
        () =>
            getApiInstance().product.getCatalog({
                uri,
            }),
        {
            onSuccess: (catalogResponse) => {
                const productFullResponse = catalogResponse.data as CatalogDetailsResponse;

                // One size ? Immediate add to cart : Open Size Selector

                if (!isOneSize(productFullResponse.product.variants)) {
                    setFullProductDataForSizeSelect(productFullResponse.product);
                } else {
                    setSelectedVariant(productFullResponse.product.variants?.[0]);
                }
            },
        },
    );

    const startQuickSizeSelect = () => {
        if (product && product.hasVariant) {
            fetchFullProduct();
        } else {
            setSelectedVariant(null);
            addToBag();
        }
    };

    /**
     * Once variant is selected, then add to bag
     */
    useEffect(() => {
        if (selectedVariant) {
            addToBag();
        }
    }, [selectedVariant]);

    const price = getProductPrice(product, selectedVariant || product);

    const isRocket = selectedVariant?.isRocket || product.isRocket;
    const isGlobal = selectedVariant?.isGlobal || ('isGlobal' in product && product.isGlobal);

    const { addToBag, isAddToBagLoading } = useAddToBag({
        sku: selectedVariant?.sku || product.sku,
        isFouponApplied: false,
        price,
        analyticsData: {
            title: `${product.brand} ${product.title}`,
            parentSku: product.parentSku || product.sku,
            isRocket: !!isRocket,
            isGlobal,
            eventLocation: analyticsData?.eventLocation || 'cart-similar',
            sizeSelected: selectedVariant?.title || product.title,
            ...analyticsData,
        },
        onSuccess: () => {
            setFullProductDataForSizeSelect(null);
            toast.success(t('out-of-stuck.item-added-to-bag'));
            setSelectedVariant(null);
        },
    });

    return {
        isAddToBagLoading,
        setSelectedVariant,
        isFetchigFullProduct,
        startQuickSizeSelect,
    };
};

export default useQuickSizeSelect;
