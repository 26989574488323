import React from 'react';

import clsx from 'clsx';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import Skeleton from './Skeleton';
import styles from './ProductSkeleton.module.scss';

type TProps = {
    isSmall?: boolean;
};

const ProductSkeleton = ({ isSmall }: TProps) => {
    const { isMobile } = useAppContext();

    return (
        <div
            className={clsx(styles.container, {
                [styles.outlined]: isMobile,
            })}
        >
            <Skeleton height={isMobile || isSmall ? 260 : 330} />
            <div className={styles.detailsContainer}>
                <Skeleton height="20px" width="140px" />
                <Skeleton height="20px" width="170px" />
                <Skeleton height="20px" width="100px" />
            </div>
        </div>
    );
};

export default ProductSkeleton;
