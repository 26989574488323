import React from 'react';

import clsx from 'clsx';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { ReviewSummary } from '@nm-namshi-frontend/services';
import { getFormattedCount } from '@nm-namshi-frontend/core/utils/helper';

import styles from './RatingsBadge.module.scss';

type TProps = {
    onClick?: () => void;
    review: ReviewSummary;
    className?: string;
};

const RatingsBadge: React.FC<TProps> = ({ className, onClick, review }) => {
    const count = review.ratingCount;

    return (
        <button type="button" onClick={onClick} className={clsx(styles.container, className)}>
            <Icon name="star" color="orange" size={11} />
            <div className={styles.rating}>{review.ratingAvg}</div>
            <div className={styles.count}>({getFormattedCount(count).toLocaleString()})</div>
        </button>
    );
};

export default RatingsBadge;
