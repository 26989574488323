import React from 'react';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './QuickBuyButton.module.scss';

type TProps = {
    onClickQuickBuy: () => void;
};

const QuickBuyButton = ({ onClickQuickBuy }: TProps) => (
    <button
        type="button"
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClickQuickBuy();
        }}
        className={styles.iconContainer}
    >
        <Icon name="plus" color="offBlack" size={15} />
    </button>
);

export default QuickBuyButton;
