import { createPortal } from 'react-dom';

import { isBrowser } from '@nm-namshi-frontend/core/utils/helper';

export type TImagePixelProps = {
    src?: string;
};
const ImagePixel = ({ src }: TImagePixelProps) => {
    if (isBrowser() && src) {
        return <>{createPortal(<img src={src} alt="" />, document.body)}</>;
    }
    return null;
};

export default ImagePixel;
