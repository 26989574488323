import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import { checkSubDepartmentExistsInDepartment } from '@nm-namshi-frontend/core/utils/departments';
import { getMappedSubDepartmentIdFromConfig } from '@nm-namshi-frontend/core/utils/subdepartments';

import ProductCarousel from './product/carousel/ProductCarousel';
import { ProductBoxVariant } from '../../types';

type TRecentlyViewedCarouselProps = {
    showDiscountPercentage?: boolean;
    selectedSubDepartmentIdOverride?: string | null;
    titleClass?: string;
    variant?: ProductBoxVariant;
    analyticsData: {
        pageType: TPageType;
        widgetId?: number;
    };
    onProductBoxClick?: () => void;
    containerClass?: string;
    renderWrapper?: React.FC<{ children: React.ReactNode; isLoading?: boolean }>;
    hideTitle?: boolean;
};

const RecentlyViewedCarousel: React.FC<TRecentlyViewedCarouselProps> = (props) => {
    const {
        analyticsData,
        containerClass = '',
        hideTitle = false,
        onProductBoxClick,
        renderWrapper: Wrapper,
        selectedSubDepartmentIdOverride,
        showDiscountPercentage,
        titleClass = '',
        variant,
    } = props;

    const { t } = useTranslation('catalog');

    const navigationData = useDepartmentStoreV2((store) => store.navigationData);
    const selectedDepartmentId = useDepartmentStoreV2((store) => store.selectedDepartmentId);
    const selectedSubDepartmentId = useDepartmentStoreV2((store) => store.selectedSubDepartmentId);

    const recentlyViewedUrl = useMemo(() => {
        if (selectedSubDepartmentIdOverride) {
            const [departmentId, subDepartmentId] = selectedSubDepartmentIdOverride.split('-');

            if (!subDepartmentId) return `${departmentId}/`;

            return `${departmentId}/${subDepartmentId}/`;
        }
        const updatedSubDepartmentId = selectedSubDepartmentIdOverride || selectedSubDepartmentId;

        const isSubDepartmentInDepartment = checkSubDepartmentExistsInDepartment({
            navigationData,
            selectedDepartmentId,
            selectedSubDepartmentId: updatedSubDepartmentId,
        });

        if (isSubDepartmentInDepartment) {
            // Pick the mapped subDepartmentId from FE config since we rely on it for now
            // @TODO : Ehab - Remove this when we update navgation-categories to reflect the correct ID's from v6
            const subDepartmentIdFromConfig = getMappedSubDepartmentIdFromConfig(
                selectedDepartmentId,
                updatedSubDepartmentId,
            );
            return `${selectedDepartmentId}/${subDepartmentIdFromConfig}/`;
        }

        return `${selectedDepartmentId}`;
    }, [navigationData, selectedDepartmentId, selectedSubDepartmentId, selectedSubDepartmentIdOverride]);

    const { data, isLoading } = useQuery([REACT_QUERY_KEYS.GET_RECENTLY_VIEWED, recentlyViewedUrl], () =>
        getApiInstance().recentCarousels.getRecentlyViewedProductsCarousel({
            departmentPath: recentlyViewedUrl,
        }),
    );

    if (!isLoading && !data?.products.length) return null;

    const renderContent = () => (
        <ProductCarousel
            products={data?.products || []}
            isLoading={isLoading}
            variant={variant}
            showDiscountPercentage={showDiscountPercentage}
            analyticsData={analyticsData}
            onProductBoxClick={onProductBoxClick}
            containerClass={containerClass}
            {...(!hideTitle ? { title: t('product-carousel.take-another-look-label'), titleClass } : {})}
        />
    );

    if (Wrapper) {
        return <Wrapper isLoading={isLoading}>{renderContent()}</Wrapper>;
    }

    return renderContent();
};

export default RecentlyViewedCarousel;
