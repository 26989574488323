import React from 'react';

import styles from './IconButton.module.scss';

type TProps = {
    children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton: React.FC<TProps> = ({ children, ...rest }) => (
    <button type="button" className={styles.btn} {...rest}>
        {children}
    </button>
);

export default IconButton;
