import React, { useEffect, useMemo, useState } from 'react';

import Carousel from 'nuka-carousel/packages/nuka/lib/carousel';
import InnerImageZoom from 'react-inner-image-zoom';
import clsx from 'clsx';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { useTranslation } from 'react-i18next';

import { getImageUrl, IMAGE_SIZE, PRODUCT_IMAGE_RATIO } from '@nm-namshi-frontend/core/utils/images';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './ImageGalleryCarousel.module.scss';
import ProductBadge from '../../productDetailPage/ProductBadge';
import ProductImage from './ProductImage';

const PRE_LOADED_CAROUSEL_IMAGES = 1;

type TProps = {
    altText: string;
    activeSlide?: number;
    badgeText?: string;
    hasImageFooter?: boolean;
    imageKeys: Array<string>;
    imageSize?: IMAGE_SIZE;
    isAutoPlayOnHoverEnabled?: boolean;
    isDotsVisible?: boolean;
    isNavigationVisible?: boolean;
    isNavigationCenter?: boolean;
    isZoomEnabled?: boolean;
    areThumbnailsVisible?: boolean;
    noImageCarousel?: boolean;
    oneImage?: boolean;
    lazyLoad?: boolean;
    variant?: 'small' | 'large';
    onSwipe?: ({ imageKey }: { imageKey: string }) => void;
    onClick?: (idx: number) => void;
};

const AUTOPLAY_INTERVAL = 1250; // in ms

type TImageGalleryItemsProps = {
    isZoomEnabled: boolean;
    imageKey: string;
    altText: string;
    imageSize?: IMAGE_SIZE;
    afterZoomIn: () => void;
    afterZoomOut: () => void;
};

const ImageGalleryItem = ({
    afterZoomIn,
    afterZoomOut,
    altText,
    imageKey,
    imageSize,
    isZoomEnabled,
}: TImageGalleryItemsProps) => {
    const imageWidth = imageSize || IMAGE_SIZE.ML;
    const imageHeight = imageWidth * PRODUCT_IMAGE_RATIO;

    const imageSrc = getImageUrl(imageKey, imageWidth);
    const zoomImageSrc = getImageUrl(imageKey); // Default to original width

    if (!isZoomEnabled) {
        return <ProductImage size={imageSize} imageKey={imageKey} altText={altText} />;
    }

    return (
        <InnerImageZoom
            hideHint
            hasSpacer
            zoomType="click"
            fadeDuration={300}
            imgAttributes={{ alt: altText }}
            src={imageSrc}
            height={imageHeight}
            width={imageWidth}
            zoomSrc={zoomImageSrc}
            afterZoomIn={afterZoomIn}
            afterZoomOut={afterZoomOut}
        />
    );
};

const ImageGalleryCarousel = ({
    activeSlide = 0,
    altText,
    areThumbnailsVisible = false,
    badgeText,
    hasImageFooter,
    imageKeys,
    imageSize,
    isAutoPlayOnHoverEnabled = false,
    isDotsVisible = true,
    isNavigationCenter,
    isNavigationVisible = true,
    isZoomEnabled = true,
    lazyLoad = true,
    noImageCarousel,
    onClick,
    onSwipe,
    oneImage,
    variant = 'large',
}: TProps) => {
    const { t } = useTranslation();
    const { isArabic, isMobile } = useAppContext();
    const [isImageZoomedIn, setIsImageZoomedIn] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [activeImageSlide, setActiveImageSlide] = useState(activeSlide);

    const [currentSlideIndex, setCurrentSlideIndex] = useState(activeSlide);

    useEffect(() => {
        if (!isHovered) {
            // Dependent on https://github.com/FormidableLabs/nuka-carousel/pull/900, disabling this feature until the nuka-carousel PR is merged :)
        }
    }, [isHovered, activeImageSlide]);

    const imageNodes = useMemo(
        () =>
            imageKeys.map((imageKey, imageKeyIndex) => {
                if (!lazyLoad || imageKeyIndex <= currentSlideIndex + PRE_LOADED_CAROUSEL_IMAGES) {
                    // loading current and next image only
                    // OR
                    // loading all images in case lazy laod is explicitly disabled
                    return (
                        <div key={imageKey} onClick={() => onClick?.(imageKeyIndex)}>
                            <ImageGalleryItem
                                isZoomEnabled={isZoomEnabled}
                                imageKey={imageKey}
                                imageSize={imageSize}
                                afterZoomIn={() => setIsImageZoomedIn(true)}
                                afterZoomOut={() => setIsImageZoomedIn(false)}
                                altText={altText}
                            />
                        </div>
                    );
                }

                return <div key={imageKey} />;
            }),
        [imageKeys, currentSlideIndex, imageSize, altText, lazyLoad],
    );

    return (
        <div
            className={styles.galleryWrapper}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {badgeText && (
                <div className={clsx(styles.badgeContainer, isImageZoomedIn ? styles.hidden : styles.visible)}>
                    <ProductBadge text={badgeText} />
                </div>
            )}

            {isMobile && areThumbnailsVisible && (
                <div className={styles.thumbnailProgress}>
                    {t('of', { pre: currentSlideIndex + 1, post: imageKeys.length })}
                </div>
            )}

            {oneImage ? (
                imageNodes[0]
            ) : (
                <Carousel
                    isRtl={isArabic}
                    // We need these props only if the carousel required to be autoplayed when hovered upon
                    {...(isAutoPlayOnHoverEnabled
                        ? {
                              autoplay: isHovered,
                              autoplayInterval: AUTOPLAY_INTERVAL,
                              pauseOnHover: false,
                              wrapAround: true,
                          }
                        : {})}
                    dragging={!!isMobile}
                    swiping={!noImageCarousel}
                    slideIndex={activeImageSlide}
                    defaultControlsConfig={{
                        pagingDotsClassName: styles.pagingDotsClass,
                        pagingDotsOnClick: (e) => {
                            e.stopPropagation();
                        },
                        pagingDotsContainerClassName: clsx(
                            styles.pagingDotsContainer,
                            variant === 'large' && styles.withMargin,
                            isImageZoomedIn ? styles.hidden : styles.visible,
                        ),
                    }}
                    renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
                        isNavigationCenter && isNavigationVisible ? (
                            <button
                                type="button"
                                onClick={previousSlide}
                                disabled={previousDisabled}
                                className={clsx(
                                    styles.buttonContainer,
                                    styles.clickable,
                                    previousDisabled && styles.disabled,
                                    isImageZoomedIn ? styles.hidden : styles.visible,
                                )}
                            >
                                <Icon name="chevronBack" />
                            </button>
                        ) : null
                    }
                    renderCenterRightControls={({ nextDisabled, nextSlide }) =>
                        isNavigationCenter && isNavigationVisible ? (
                            <button
                                type="button"
                                onClick={nextSlide}
                                disabled={nextDisabled}
                                className={clsx(
                                    styles.buttonContainer,
                                    styles.clickable,
                                    nextDisabled && styles.disabled,
                                    isImageZoomedIn ? styles.hidden : styles.visible,
                                )}
                            >
                                <Icon name="chevronForward" />
                            </button>
                        ) : null
                    }
                    renderBottomCenterControls={({ currentSlide, slideCount }) =>
                        isDotsVisible ? (
                            <ul
                                className={clsx(styles.dotsContainer, hasImageFooter && styles.dotsContainerWithFooter)}
                            >
                                {[...Array(slideCount)].map((ele, index) => (
                                    <li key={`${index + 1}-dot`}>
                                        <div className={clsx(styles.dot, currentSlide === index && styles.current)} />
                                    </li>
                                ))}
                            </ul>
                        ) : null
                    }
                    renderBottomLeftControls={({ previousDisabled, previousSlide }) =>
                        !isNavigationCenter && isNavigationVisible ? (
                            <button
                                type="button"
                                onClick={previousSlide}
                                disabled={previousDisabled}
                                className={clsx(
                                    styles.buttonContainer,
                                    styles.clickable,
                                    previousDisabled && styles.disabled,
                                    isImageZoomedIn ? styles.hidden : styles.visible,
                                )}
                            >
                                <Icon name="chevronBack" />
                            </button>
                        ) : null
                    }
                    renderBottomRightControls={({ nextDisabled, nextSlide }) =>
                        !isNavigationCenter && isNavigationVisible ? (
                            <button
                                type="button"
                                onClick={nextSlide}
                                disabled={nextDisabled}
                                className={clsx(
                                    styles.buttonContainer,
                                    styles.clickable,
                                    nextDisabled && styles.disabled,
                                    isImageZoomedIn ? styles.hidden : styles.visible,
                                )}
                            >
                                <Icon name="chevronForward" />
                            </button>
                        ) : null
                    }
                    afterSlide={(newSlideIndex) => {
                        setCurrentSlideIndex(newSlideIndex);
                        onSwipe?.({ imageKey: imageKeys[newSlideIndex] });
                    }}
                >
                    {imageKeys.length ? (
                        imageNodes
                    ) : (
                        <ProductImage imageKey="" size={imageSize || IMAGE_SIZE.ML} altText={altText} />
                    )}
                </Carousel>
            )}

            {areThumbnailsVisible && (isMobile ? true : !isImageZoomedIn) && (
                <div className={styles.thumbnailsWrapper}>
                    <Carousel
                        isRtl={isArabic}
                        cellSpacing={6}
                        slideIndex={activeImageSlide}
                        slidesToShow={8}
                        dragging={!!isMobile}
                        cellAlign="center"
                        renderBottomCenterControls={() => null}
                        renderCenterLeftControls={({ currentSlide, previousDisabled, previousSlide }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    previousSlide();
                                    setActiveImageSlide(currentSlide - 1);
                                }}
                                disabled={previousDisabled}
                                className={clsx(styles.buttonContainer, previousDisabled && styles.disabled)}
                            >
                                <Icon name="chevronBack" />
                            </button>
                        )}
                        renderCenterRightControls={({ currentSlide, nextDisabled, nextSlide }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    nextSlide();
                                    setActiveImageSlide(currentSlide + 1);
                                }}
                                disabled={nextDisabled}
                                className={clsx(styles.buttonContainer, nextDisabled && styles.disabled)}
                            >
                                <Icon name="chevronForward" />
                            </button>
                        )}
                    >
                        {imageKeys.map((imageKey, index) => (
                            <button
                                type="button"
                                className={clsx(
                                    styles.thumbnailItem,
                                    styles.clickable,
                                    index === activeImageSlide && styles.selected,
                                )}
                                key={imageKey}
                                onClick={() => setActiveImageSlide(index)}
                            >
                                <ProductImage imageKey={imageKey} size={IMAGE_SIZE.S} altText={altText} />
                            </button>
                        ))}
                    </Carousel>
                </div>
            )}
        </div>
    );
};
export default ImageGalleryCarousel;
