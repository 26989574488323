import { useCallback, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import SizeSelector from '@nm-namshi-frontend/core/page_components//productDetailPage/SizeSelector';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import DeleteWishlistItemModal from '@nm-namshi-frontend/core/page_components/wishlists/DeleteWishlistItemModal';
import { isOneSize, isTimerSaleActive } from '@nm-namshi-frontend/core/utils/helper';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { TWishlistProductBox } from '@nm-namshi-frontend/core/page_components/shared/product/ProductBox';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import TimerSale, { TimerSaleVariant } from '@nm-namshi-frontend/core/page_components/shared/product/TimerSale';
import ProductDiscountTag from '@nm-namshi-frontend/core/page_components/shared/product/ProductDiscountTag';
import ImageGalleryCarousel from '@nm-namshi-frontend/core/page_components/shared/product/ImageGalleryCarousel';
import MoveToBag from '@nm-namshi-frontend/core/page_components/productDetailPage/wishlist/modals/MoveToBag';
import { CustomerDataResponse } from '@nm-namshi-frontend/services';
import AddToBagButton from '@nm-namshi-frontend/core/page_components/productDetailPage/AddToBagButton';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useIsSettleXAddress from '@nm-namshi-frontend/core/hooks/useIsSettleXAddress';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { useOutsideClick } from '@nm-namshi-frontend/core/hooks/useOutsideClick';

import styles from './DesktopWishlistProductBox.module.scss';

const DesktopWishlistProductBox = ({
    isWardrobeGuest,
    noImageCarousel,
    onClick,
    product,
    showDiscountPercentage = true,
}: TWishlistProductBox) => {
    const { t } = useTranslation('wishlist');
    const { locale } = useAppPathname();
    const { lang } = getLocaleConfig(locale);

    const [selectedSku, setSelectedSku] = useState<string>(product && isOneSize(product?.variants) ? product.sku : '');
    const [isSizeSelectorOpen, setIsSizeSelectorOpen] = useState(false);
    const [isSizeError, setSizeError] = useState(false);
    const [isDeleteItemModalVisible, setIsDeleteItemModalVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useOutsideClick({
        callback: () => {
            setSizeError(false);
        },
        ref,
    });
    const { brand, discountPercent, discountTag, imageKeys, normalPrice, salePrice, sku, timer, title, uri } = product;

    const isTimerActive = isTimerSaleActive(timer);

    // Renderers
    const renderTitleAndBrand = () => (
        <>
            <div className={styles.brand}>{brand}</div>
            <div className={styles.productTitle}>{title}</div>
        </>
    );

    const renderProductImage = useCallback(() => {
        const imageSize = IMAGE_SIZE.L;

        if (isEmpty(imageKeys)) {
            return <ProductImage altText={title || ''} imageKey="" size={imageSize} />;
        }
        return (
            <AlteredLink locale={locale} href={uri}>
                <ImageGalleryCarousel
                    variant="small"
                    imageKeys={imageKeys}
                    isAutoPlayOnHoverEnabled
                    isNavigationVisible={false}
                    isZoomEnabled={false}
                    imageSize={imageSize}
                    noImageCarousel={noImageCarousel}
                    altText={title || ''}
                />
            </AlteredLink>
        );
    }, [imageKeys, sku]);

    const renderPrice = () => {
        if (normalPrice) {
            return (
                <ProductPrice
                    price={normalPrice}
                    {...(salePrice ? { salePrice } : {})}
                    {...(discountPercent ? { discountPercent } : {})}
                    showDiscount={showDiscountPercentage}
                    size="large"
                />
            );
        }

        return null;
    };

    const renderDeleteWishlistItemModal = () => (
        <DeleteWishlistItemModal
            analyticsData={{
                parentSku: product.parentSku,
                isRocket: !!product.isRocket,
            }}
            product={product}
            isOpen={isDeleteItemModalVisible}
            onClose={() => setIsDeleteItemModalVisible(false)}
            onSuccess={() => setIsDeleteItemModalVisible(false)}
        />
    );

    const renderCTA = () => {
        if (isWardrobeGuest) {
            return (
                <AddToBag
                    isLoading={false}
                    productData={product}
                    selectedSku={selectedSku}
                    onNoSizeSelected={() => setSizeError(true)}
                    onSuccess={() => setIsSizeSelectorOpen(false)}
                    analyticsData={{
                        eventLocation: 'wardrobe',
                        sizeSelected: product.title,
                    }}
                />
            );
        }

        return (
            <MoveToBag
                product={product}
                selectedSku={selectedSku}
                onNoSizeSelected={() => setSizeError(true)}
                onSuccess={() => setIsSizeSelectorOpen(false)}
                analyticsData={{
                    eventLocation: 'wishlist',
                    sizeSelected: product.title,
                }}
            />
        );
    };

    return (
        <>
            {renderDeleteWishlistItemModal()}
            <div className={styles.container} onClick={onClick} ref={ref}>
                <div>
                    <div className={styles.imageContainer}>
                        {!isWardrobeGuest && (
                            <button
                                type="button"
                                className={styles.removeBtn}
                                onClick={() => setIsDeleteItemModalVisible(true)}
                            >
                                <Icon name="remove" size={15} />
                            </button>
                        )}
                        {renderProductImage()}
                        {isTimerActive && (
                            <div className={styles.timerContainer}>
                                <TimerSale timerSaleConfig={timer} lang={lang} variant={TimerSaleVariant.tag} />
                            </div>
                        )}
                        {discountTag && (
                            <div className={styles.discountTagWrapper}>
                                <ProductDiscountTag tag={discountTag} />
                            </div>
                        )}
                    </div>
                    <AlteredLink locale={locale} href={uri}>
                        <div className={styles.detailsContainer}>
                            {renderTitleAndBrand()}
                            <div className={styles.detailsWrapper}>
                                {renderPrice()}
                                {product.isRocket && <Icon name="rocket" />}
                            </div>
                        </div>
                    </AlteredLink>
                </div>

                <div className={styles.productFooter}>
                    {!isOneSize(product.variants) ? (
                        <SizeSelector
                            isOpen={isSizeSelectorOpen}
                            setIsOpen={setIsSizeSelectorOpen}
                            onSelect={setSelectedSku}
                            selectedSku={selectedSku}
                            productData={product}
                        />
                    ) : (
                        <div />
                    )}

                    {renderCTA()}
                    <span className={clsx(styles.sizeError, !(isSizeError && !selectedSku) && styles.hidden)}>
                        {t('common.no-size-selected')}
                    </span>
                </div>
            </div>
        </>
    );
};

const AddToBag: typeof AddToBagButton = (props) => {
    const { productData, selectedSku } = props;

    const rqClient = useQueryClient();
    const { isXAddressKeySattled } = useIsSettleXAddress();

    const selectedVariant = useMemo(
        () => productData.variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!productData.variants, selectedSku],
    );

    const customerData = rqClient.getQueryData<CustomerDataResponse>([REACT_QUERY_KEYS.GET_CUSTOMER])?.data;

    const { data: customerAddressesData, isLoading: isCustomerAddressesLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            enabled: !!customerData,
        },
    );

    const { data: extrasData, isFetching: isExtrasLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CATALOG_EXTRAS, selectedSku],
        () =>
            getApiInstance().extras.catalogExtras({
                requestBody: {
                    uri: selectedVariant ? `/${selectedVariant.sku}/p` : `/${productData.sku}/p`,
                },
                ...(customerAddressesData?.selected?.addressKey
                    ? { xAddressKey: customerAddressesData?.selected?.addressKey }
                    : {}),
            }),
        {
            enabled: isXAddressKeySattled,
        },
    );

    const areAddToBagPrerequisitesLoading = customerData
        ? isCustomerAddressesLoading || isExtrasLoading
        : isExtrasLoading;

    return <AddToBagButton {...props} isLoading={areAddToBagPrerequisitesLoading} extrasData={extrasData} />;
};

export default DesktopWishlistProductBox;
