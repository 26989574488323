import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { TWishlistProductBox } from '@nm-namshi-frontend/core/page_components/shared/product/ProductBox';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { isOneSize, isTimerSaleActive } from '@nm-namshi-frontend/core/utils/helper';
import { CustomerDataResponse } from '@nm-namshi-frontend/services';
import TimerSale, { TimerSaleVariant } from '@nm-namshi-frontend/core/page_components/shared/product/TimerSale';
import ProductDiscountTag from '@nm-namshi-frontend/core/page_components/shared/product/ProductDiscountTag';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import Button from '@nm-namshi-frontend/core/components/Button';
import SizeSelector from '@nm-namshi-frontend/core/page_components/productDetailPage/SizeSelector';
import MoveToBag from '@nm-namshi-frontend/core/page_components/productDetailPage/wishlist/modals/MoveToBag';
import ProductRecommendations from '@nm-namshi-frontend/core/page_components/shared/product/ProductRecommendations';
import { ProductBoxVariant } from '@nm-namshi-frontend/core/types';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import AddToBagButton from '@nm-namshi-frontend/core/page_components/productDetailPage/AddToBagButton';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useIsSettleXAddress from '@nm-namshi-frontend/core/hooks/useIsSettleXAddress';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import NativeImageSlider from '@nm-namshi-frontend/core/page_components/shared/product/NativeImageSlider';
import ProductIndicators from '@nm-namshi-frontend/core/page_components/shared/product/ProductIndicators';

import styles from './MobileWishlistProductBox.module.scss';

const MobileWishlistProductBox = ({
    analyticsData,
    isWardrobeGuest = false,
    onClick,
    product,
    renderActions,
    showDiscountPercentage = true,
}: TWishlistProductBox) => {
    const { t } = useTranslation('wishlist');
    const { locale } = useAppPathname();
    const { lang } = getLocaleConfig(locale);

    const [selectedSku, setSelectedSku] = useState<string>(product && isOneSize(product?.variants) ? product.sku : '');

    const [isSimilarItemsVisible, setSimilarItemsModal] = useState(false);
    const [isSizeSelectorOpen, setIsSizeSelectorOpen] = useState(false);

    const {
        brand,
        discountPercent,
        discountTag,
        imageKeys,
        normalPrice,
        parentSku,
        salePrice,
        sku,
        timer,
        title,
        uri,
    } = product;

    const isTimerActive = isTimerSaleActive(timer);

    const showSizeSelector = !isOneSize(product.variants);

    // Renderers

    const renderTitleAndBrand = () => (
        <div>
            <div className={styles.brand}>{brand}</div>
            <div className={styles.productTitle}>{title}</div>
        </div>
    );

    const renderProductImage = useCallback(() => {
        const imageSize = IMAGE_SIZE.ML;

        if (isEmpty(imageKeys)) {
            return <ProductImage altText={title || ''} imageKey="" size={imageSize} />;
        }
        return (
            <AlteredLink locale={locale} href={uri}>
                <NativeImageSlider
                    data={imageKeys.map((imageKey) => ({ imageKey, key: imageKey }))}
                    renderBottomControls={({ currentSlide, slideCount }) => (
                        <ProductIndicators currentSlide={currentSlide} slideCount={slideCount} />
                    )}
                    renderSlide={(item) => (
                        <ProductImage size={imageSize} imageKey={item.imageKey} altText={title || ''} />
                    )}
                />
            </AlteredLink>
        );
    }, [!!imageKeys.length, sku]);

    const renderPrice = () => {
        if (normalPrice) {
            return (
                <ProductPrice
                    price={normalPrice}
                    {...(salePrice ? { salePrice } : {})}
                    {...(discountPercent ? { discountPercent } : {})}
                    showDiscount={showDiscountPercentage}
                />
            );
        }

        return null;
    };

    const renderCTA = ({ size = 'medium' }: { size?: 'small' | 'medium' }) => {
        if (isWardrobeGuest) {
            return (
                <AddToBag
                    size={size}
                    isLoading={false}
                    productData={product}
                    onNoSizeSelected={() => setIsSizeSelectorOpen(true)}
                    selectedSku={selectedSku}
                    analyticsData={{
                        eventLocation: 'wardrobe',
                        sizeSelected: product.title,
                    }}
                    onSuccess={() => {
                        setIsSizeSelectorOpen(false);
                    }}
                />
            );
        }

        return (
            <MoveToBag
                size={size}
                product={product}
                selectedSku={selectedSku}
                onNoSizeSelected={() => setIsSizeSelectorOpen(true)}
                analyticsData={{
                    eventLocation: 'wishlist',
                    sizeSelected: product.title,
                }}
                onSuccess={() => {
                    setIsSizeSelectorOpen(false);
                }}
            />
        );
    };

    return (
        <>
            {showSizeSelector && (
                <SizeSelector
                    isOpen={isSizeSelectorOpen}
                    setIsOpen={setIsSizeSelectorOpen}
                    onSelect={setSelectedSku}
                    selectedSku={selectedSku}
                    productData={product}
                    renderCTA={renderCTA}
                />
            )}
            <div className={styles.container}>
                <div className={styles.productBox} onClick={onClick}>
                    <div className={styles.imageContainer}>
                        {isTimerActive && (
                            <div className={styles.timerContainer}>
                                <TimerSale timerSaleConfig={timer} lang={lang} variant={TimerSaleVariant.tag} />
                            </div>
                        )}
                        {renderProductImage()}
                        {discountTag && (
                            <div className={styles.discountTagWrapper}>
                                <ProductDiscountTag tag={discountTag} />
                            </div>
                        )}
                    </div>
                    <div className={styles.detailsContainer}>
                        <div className={styles.detailsHeader}>
                            <div className={styles.titleAndBrand}>
                                {renderTitleAndBrand()}
                                {!isWardrobeGuest && renderActions && <div>{renderActions()}</div>}
                            </div>
                        </div>

                        <div className={styles.footer}>
                            <div className={styles.detailsWrapper}>
                                {renderPrice()}
                                {product.isRocket && <Icon name="rocket" size={20} />}
                            </div>
                            {renderCTA({ size: 'small' })}
                            <Button size="small" variant="secondary" onClick={() => setSimilarItemsModal(true)}>
                                <p>{t('common.similarProducts')}</p>
                            </Button>
                        </div>
                    </div>
                </div>

                <Modal
                    title={t('common.similarProducts')}
                    open={isSimilarItemsVisible}
                    onClose={() => setSimilarItemsModal(false)}
                    contentClassName={styles.recosContent}
                >
                    <ProductRecommendations
                        recoParams={{
                            sku: parentSku,
                            placement: 'product-detail-similar',
                        }}
                        variant={ProductBoxVariant.SEARCHSUGGESTION}
                        analyticsData={{
                            pageType: analyticsData?.pageType || 'pdp',
                            pagePath: product.uri,
                            widgetId: WIDGET_IDS.SIMILAR_PRODUCTS,
                        }}
                        showTitle={false}
                    />
                </Modal>
            </div>
        </>
    );
};

const AddToBag: typeof AddToBagButton = (props) => {
    const { productData, selectedSku } = props;

    const rqClient = useQueryClient();
    const { isXAddressKeySattled } = useIsSettleXAddress();

    const selectedVariant = useMemo(
        () => productData.variants?.find(({ sku: variantSku }) => variantSku === selectedSku),
        [!!productData.variants, selectedSku],
    );

    const customerData = rqClient.getQueryData<CustomerDataResponse>([REACT_QUERY_KEYS.GET_CUSTOMER])?.data;

    const { data: customerAddressesData, isLoading: isCustomerAddressesLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            enabled: !!customerData,
        },
    );

    const { data: extrasData, isFetching: isExtrasLoading } = useQuery(
        [REACT_QUERY_KEYS.GET_CATALOG_EXTRAS, selectedSku],
        () =>
            getApiInstance().extras.catalogExtras({
                requestBody: {
                    uri: selectedVariant ? `/${selectedVariant.sku}/p` : `/${productData.sku}/p`,
                },
                ...(customerAddressesData?.selected?.addressKey
                    ? { xAddressKey: customerAddressesData?.selected?.addressKey }
                    : {}),
            }),
        {
            enabled: isXAddressKeySattled,
        },
    );

    const areAddToBagPrerequisitesLoading = customerData
        ? isCustomerAddressesLoading || isExtrasLoading
        : isExtrasLoading;

    return <AddToBagButton {...props} isLoading={areAddToBagPrerequisitesLoading} extrasData={extrasData} />;
};

export default MobileWishlistProductBox;
