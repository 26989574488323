import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getTimeLeft } from '../../utils/helper';

const COUNT_DOWN_INTERVAL = 1000;

type TCountDownTimer = {
    label?: string;
    endTime: number;
    className?: string;
    onExpiry?: () => void;
    showExtendedUnits?: boolean; // hr vs h, min vs m, sec vs s
    showUnits?: boolean;
    hideSeconds?: boolean;
    hideSeparator?: boolean;
    addSpacing?: boolean;
    digitStyle?: string;
};

const padStart = (value: number) => `${value}`.padStart(2, '0');

const CountDownTimer = ({
    addSpacing,
    className,
    digitStyle,
    endTime,
    hideSeconds = false,
    hideSeparator = false,
    label,
    onExpiry,
    showExtendedUnits = false,
    showUnits = false,
}: TCountDownTimer) => {
    const { t } = useTranslation('common');
    const [timer, setTimer] = useState(getTimeLeft(endTime));
    const frameRef = useRef(0);

    useEffect(() => {
        let prevTime = 0;
        const countDown = (currTime: number) => {
            if (!prevTime || currTime - prevTime >= COUNT_DOWN_INTERVAL) {
                prevTime = currTime;
                const updatedTimer = getTimeLeft(endTime);
                if (updatedTimer) {
                    setTimer(updatedTimer);
                }
                // time is up
                else {
                    cancelAnimationFrame(frameRef.current);
                    onExpiry?.();
                    // set to 0 if time is up
                    frameRef.current = 0;
                }
            }

            // if 0 then time is up
            if (frameRef.current !== 0) {
                frameRef.current = requestAnimationFrame(countDown);
            }
        };
        frameRef.current = requestAnimationFrame(countDown);

        return () => cancelAnimationFrame(frameRef.current);
    }, [endTime]);

    if (!timer) return null;
    return (
        <span dir="ltr" className={className}>
            {label ? `${label} ` : null}
            <span className={digitStyle} style={addSpacing ? { marginRight: '2px' } : undefined}>
                {padStart(timer.hours)}
                {showUnits && <span>{showExtendedUnits ? t('hr') : 'h'}</span>}
            </span>
            {!hideSeparator && ':'}
            <span className={digitStyle} style={addSpacing ? { margin: '0 2px' } : undefined}>
                {padStart(timer.minutes)}
                {showUnits && <span>{showExtendedUnits ? t('min') : 'm'}</span>}
            </span>
            {!hideSeconds && (
                <>
                    {!hideSeparator && ':'}
                    <span className={digitStyle}>
                        {' '}
                        {padStart(timer.seconds)}
                        {showUnits && <span>{showExtendedUnits ? t('sec') : 's'}</span>}
                    </span>
                </>
            )}
        </span>
    );
};

export default CountDownTimer;
