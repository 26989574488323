import React, { SyntheticEvent, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';
import HeartIcon from '@nm-namshi-frontend/core/components/HeartIcon';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';
import IconButton from '@nm-namshi-frontend/core/components/IconButton';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

type TProps = {
    analyticsData: {
        parentSku?: string;
        price: number;
        isRocket: boolean;
        isGlobal: boolean;
        pageType?: TPageType;
        pagePath?: string;
    };
    sku: string;
    isActive?: boolean;
    isVisibleWhenLoggedInOnly?: boolean;
    parentSku: string;
};

const WishlistControl = ({
    analyticsData,
    isActive = false,
    isVisibleWhenLoggedInOnly = false,
    parentSku,
    sku,
}: TProps) => {
    const { setAuthModal } = useLayoutStore();
    const [isHeartFilled, setIsHeartFilled] = useState(isActive);
    const { locale } = useAppPathname();
    const { currencyCodeEN } = getLocaleConfig(locale);
    const queryClient = useQueryClient();
    const { conversionRates } = useConfigContext();

    const convertedPrice = convertPrice({
        conversionRates,
        price: analyticsData.price,
        toCurrency: currencyCodeEN,
    });

    useEffect(() => {
        setIsHeartFilled(isActive);
    }, [isActive]);

    // Customer Data
    const customerDataResponse = useCustomerStore((store) => store.customerData);

    const customerData = customerDataResponse?.data;

    const { isLoading: isAdding, mutate: addToWishlist } = useMutation(
        () =>
            getApiInstance().wardrobe.addItem1({
                requestBody: {
                    parentSku,
                    sku,
                },
            }),
        {
            onSuccess: () => {
                setIsHeartFilled(true);
                return queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
            },
            onError: (error: string) => {
                toast.error(error.toString());
                setIsHeartFilled(false);
            },
        },
    );

    const { isLoading: isRemoving, mutate: removeFromWishlist } = useMutation(
        () =>
            getApiInstance().wardrobe.deleteItem({
                parentSku,
            }),

        {
            onSuccess: () => {
                queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
                setIsHeartFilled(false);

                return queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_PARENTSKUS]);
            },
            onError: (error: string) => {
                toast.error(error.toString());
            },
        },
    );
    const handleWishlistIconClick = async (e: SyntheticEvent<HTMLElement>): Promise<void> => {
        e.stopPropagation();
        e.preventDefault();

        if (!customerData) {
            setAuthModal({ show: true, onSuccess: handleToggleWishlist });
            return;
        }

        handleToggleWishlist();
    };

    const handleToggleWishlist = () => {
        if (isHeartFilled) {
            // If already in wishlist
            removeFromWishlist();

            // tracking
            trackEvent({
                event: 'wishlist_remove',
                sku,
                ...(analyticsData.parentSku
                    ? {
                          parentSku: analyticsData.parentSku,
                      }
                    : {}),
                isRocket: analyticsData.isRocket,
            });
        } else {
            // If not already in wishlist
            addToWishlist();
            setIsHeartFilled(true);

            trackEvent({
                event: 'wishlist_add',
                sku,
                parentSku: analyticsData.parentSku,
                price: convertedPrice,
                currency: currencyCodeEN,
                isRocket: analyticsData.isRocket,
                isGlobal: analyticsData.isGlobal,
                pageType: analyticsData.pageType,
                ...(analyticsData.pagePath
                    ? {
                          pagePath: analyticsData.pagePath,
                      }
                    : {}),
            });
        }
    };

    if (isVisibleWhenLoggedInOnly && !customerData) return null;

    return (
        <IconButton data-testid="addItemToWishlist" disabled={isAdding || isRemoving} onClick={handleWishlistIconClick}>
            <HeartIcon size={24} active={isActive} isLoading={isAdding || isRemoving} />
        </IconButton>
    );
};

WishlistControl.displayName = 'WishlistControl';

export default WishlistControl;
