import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './SponsoredBadge.module.scss';

const SponsoredBadge: React.FC = () => {
    const { t } = useTranslation();

    return <div className={styles.container}>{t('sponsoredLabel')}</div>;
};

export default SponsoredBadge;
